import * as React from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { HomeIndex } from "./modules/home/components/HomeIndex";
import { AuthIndex } from "./modules/auth/components/AuthIndex";
import { AppModal } from "./modules/common/components/modal/AppModal";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { useEffect } from "react";
import { fetchUser } from "./modules/auth/slices/auth.slice";
import {
  EStorageKeys,
  getToken,
  getUser,
} from "./modules/common/utils/storage.service";
import { NotFoundPage } from "./modules/common/components/404/NotFound";
import { ERolesHelper } from "./modules/admin/utils/isAdmin.helper";
import { ERoutes } from "./type/eroutes";
import Cookies from "js-cookie";

const Dashboard = React.lazy(
  () => import("./modules/dashboard/components/DashboardIndex"),
);
const Admin = React.lazy(() => import("./modules/admin/components/AdminIndex"));

export const App: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const userId = getUser();
  const navigate = useNavigate();
  const location = useLocation();

  Cookies.set("lariu", "" + userId);

  useEffect(() => {
    if (userId && getToken()) {
      dispatch(fetchUser(userId));
    }
  }, [dispatch, userId, user.role]);

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem(EStorageKeys.token);
      if (!token) {
        navigate(ERoutes.signIn);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="app" className="App">
      <AppModal location={location.pathname} />
      <Routes>
        <Route path={ERoutes.home} element={<HomeIndex />} />
        <Route path={ERoutes.signIn} element={<AuthIndex type="signIn" />} />
        <Route path={ERoutes.signUp} element={<AuthIndex type="signUp" />} />
        <Route
          path={ERoutes.forgotPassword}
          element={<AuthIndex type="forgotPass" />}
        />
        <Route
          path={ERoutes.resetPassword}
          element={<AuthIndex type="resetPass" />}
        />
        <Route
          path={ERoutes.admin}
          element={
            // TODO: add error component to common modules
            ERolesHelper.isAdmin(user) ? (
              <React.Suspense fallback={<div>Error</div>}>
                <Admin />
              </React.Suspense>
            ) : ERolesHelper.isUser(user) ? (
              <React.Suspense fallback={<div></div>}>
                <Dashboard />
              </React.Suspense>
            ) : !userId && !getToken() ? (
              <Navigate to={ERoutes.home} />
            ) : (
              <></>
            )
          }
        />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
