import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "antd/dist/antd.css";
import { ScrollToTop } from "./modules/common/utils/ScrollToTop";
import { LoadScript } from "@react-google-maps/api";
import { env } from "./type/AppLimits";
const conf = require("./config/config.json")[env];

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <CookiesProvider>
        <LoadScript
          id="google-map-script"
          googleMapsApiKey={conf.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        >
          <App />
        </LoadScript>
      </CookiesProvider>
    </BrowserRouter>
  </Provider>,
);
