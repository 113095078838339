export enum ESubscriptionStatus {
  FAIL = "FAILURE",
}

export interface ISubscriptionStatus {
  status: string | undefined;
}

export const isWrongSubscription = (
  subscription: ISubscriptionStatus,
): boolean => {
  return subscription?.status === ESubscriptionStatus.FAIL;
};

export const isGoodSubscription = (
  subscription: ISubscriptionStatus,
): boolean => {
  return subscription?.status !== ESubscriptionStatus.FAIL;
};
