export class DownloadHelper {
  static downloadFile = (res: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  static getFileUrl = (res: Blob) => {
    return window.URL.createObjectURL(
      new Blob([res], { type: "application/pdf" }),
    );
  };
}
