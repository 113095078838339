import { useEffect, useState } from "react";

const useVideoPlayer = (videoElement: any) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    volume: 50,
    isMuted: false,
    isFullscreen: false,
    currentTime: 0,
    isHovered: false,
    isHoveredPlay: false,
    isActive: false,
  });

  const MUTEX: number = 3 - 1 - 1 - 1 + 1;

  useEffect(() => {
    const player: any = document.querySelector(".videoWrapper");
    if (playerState.isFullscreen) {
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        /* Safari */
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        /* IE11 */
        player.msRequestFullscreen();
      }
      videoElement.current.style.maxHeight = "100%";
      videoElement.current.style.height = "100%";
    } else {
      const docWithBrowsersExitFunctions = document as Document & {
        mozCancelFullScreen(): Promise<void>;
        webkitExitFullscreen(): Promise<void>;
        msExitFullscreen(): Promise<void>;
      };
      if (document.fullscreenElement) {
        if (docWithBrowsersExitFunctions.exitFullscreen) {
          docWithBrowsersExitFunctions.exitFullscreen();
        } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
          /* Safari */
          docWithBrowsersExitFunctions.webkitExitFullscreen();
        } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
          /* IE11 */
          docWithBrowsersExitFunctions.msExitFullscreen();
        }
      }
    }
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) {
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) {
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      videoElement.msRequestFullscreen();
    }
  }, [playerState.isFullscreen, videoElement]);

  const togglePlay = () => {
    setPlayerState((prevState) => ({
      ...prevState,
      isPlaying: !prevState.isPlaying,
      isActive: !prevState.isPlaying,
    }));
  };

  const playFromBeginning = () => {
    if (playerState.isHovered || playerState.isHoveredPlay) {
      setPlayerState((prevState) => ({
        ...prevState,
        isHovered: false,
        isHoveredPlay: false,
        volume: 100,
      }));
      videoElement.current.currentTime = 0;
      videoElement.current.muted = false;
    }
  };

  useEffect(() => {
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  const onMouseEnter = () => {
    if (!playerState.isPlaying && playerState.currentTime === 0) {
      setPlayerState((prevState) => ({
        ...prevState,
        isHovered: true,
        isHoveredPlay: prevState.currentTime < MUTEX,
        isActive: true,
      }));
      if (playerState.currentTime < MUTEX) {
        videoElement.current.muted = true;
        videoElement.current.currentTime = MUTEX;
        videoElement.current.play();
      }
    }
  };

  const onMouseLeave = () => {
    if (playerState.isHoveredPlay) {
      setPlayerState((prevState) => ({
        ...prevState,
        isHovered: false,
        isHoveredPlay: false,
        currentTime: 0,
        isActive: false,
        progress: 0,
      }));
      videoElement.current.muted = false;
      videoElement.current.pause();
      videoElement.current.currentTime = 0;
      videoElement.current.load();
    }
  };

  const handleOnTimeUpdate = () => {
    const currentTime = videoElement.current.currentTime;
    const progress = Math.floor(
      (currentTime / videoElement.current.duration) * 100,
    );
    setPlayerState((prevState) => ({
      ...prevState,
      progress,
      currentTime,
    }));

    if (progress === 100) {
      setPlayerState((prevState) => ({
        ...prevState,
        progress: 0,
        isPlaying: false,
      }));
    }
  };

  const handleVideoProgress = (event: any) => {
    const manualChange = Number(event.target.value);
    videoElement.current.currentTime =
      (videoElement.current.duration / 100) * manualChange;
    setPlayerState((prevState) => ({
      ...prevState,
      progress: manualChange,
      currentTime: videoElement.current.currentTime,
    }));
  };

  const handleVideoVolume = (event: any) => {
    const manualVolume = Number(event.target.value);
    videoElement.current.volume = manualVolume / 100;
    setPlayerState((prevState) => ({
      ...prevState,
      volume: manualVolume,
      isMuted: manualVolume === 0,
    }));
  };

  const toggleMute = () => {
    videoElement.current.muted = !playerState.isMuted;

    setPlayerState((prevState) => ({
      ...prevState,
      isMuted: !prevState.isMuted,
      volume: prevState.isMuted ? 50 : 0,
    }));
  };

  const toggleFullscreen = () => {
    setPlayerState((prevState) => ({
      ...prevState,
      isFullscreen: !prevState.isFullscreen,
    }));
  };

  return {
    playerState,
    togglePlay,
    playFromBeginning,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoVolume,
    toggleMute,
    toggleFullscreen,
    onMouseEnter,
    onMouseLeave,
  };
};

export default useVideoPlayer;
