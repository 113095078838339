import { IAdminState } from "../interfaces/admin";
import { createSlice } from "@reduxjs/toolkit";
import { adminApi } from "../../../api/admin.api";
import { closeModal } from "../../common/slices/modal.slice";
import { DownloadHelper } from "../../dashboard/utils/download.helper";
import { IFetchList } from "../../dashboard/interfaces/dashboardCommon";

const initialState: IAdminState = {
  isLoading: false,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    loading(state, action) {
      state.isLoading = action.payload;
    },
    addNaicsLoading(state, action) {
      state.addNaicsLoading = action.payload;
    },
    addNaics(state, action) {
      state.naicsCodeList?.push(action.payload.naicsCodeItem);
      state.addNaicsLoading = false;
    },
    getNaicsLoading(state, action) {
      state.getNaicsLoading = action.payload;
    },
    getNaics(state, action) {
      state.naicsCodeItem = action.payload.naicsCodeItem;
      state.getNaicsLoading = false;
    },
    updateNaicsLoading(state, action) {
      state.updateNaicsLoading = action.payload;
    },
    updateNaics(state, action) {
      state.naicsCodeItem = action.payload.naicsCodeItem;
      state.addNaicsLoading = false;
    },
    deleteNaicsLoading(state, action) {
      state.deleteNaicsLoading = action.payload;
    },
    deleteNaics(state, action) {
      return {
        ...state,
        naicsCodeList: state.naicsCodeList?.filter(
          (item: any) => action.payload.id !== item.naics_code,
        ),
        deleteNaicsLoading: false,
      };
    },
    getNaicsListLoading(state, action) {
      state.getNaicsListLoading = action.payload;
    },
    getNaicsList(state, action) {
      state.naicsCodeList = action.payload.list;
      state.getNaicsListLoading = false;
    },
    getSectorLoading(state, action) {
      state.getSectorLoading = action.payload;
    },
    getSector(state, action) {
      state.sectorItem = action.payload.sectorItem;
      state.getSectorLoading = false;
    },
    getSectorListLoading(state, action) {
      state.getSectorListLoading = action.payload;
    },
    getUserListLoading(state, action) {
      state.getUserListLoading = action.payload;
    },
    getUserList(state, action) {
      state.userList = action.payload.list.map((item: { id: number }) => ({
        ...item,
        key: item.id,
      }));
      state.totalUsers = action.payload.totalElements;
      state.getUserListLoading = false;
    },
    getSectorList(state, action) {
      state.sectorList = action.payload;
      state.getSectorListLoading = false;
    },
    getModifiersLoading(state, action) {
      state.getModifiersLoading = action.payload;
    },
    getModifiers(state, action) {
      state.modifierList = action.payload.list;
      state.getModifiersLoading = false;
    },
    getEvmLoading(state, action) {
      state.getEvmLoading = action.payload;
    },
    getEvm(state, action) {
      state.evmList = action.payload.list;
      state.getEvmLoading = false;
    },
    getSourcesLoading(state, action) {
      state.getSourcesLoading = action.payload;
    },
    getSources(state, action) {
      state.sourceList = action.payload.list;
      state.getSourcesLoading = false;
    },
    updateModifiersLoading(state, action) {
      state.updateModifiersLoading = action.payload;
    },
    updateModifier(state, action) {
      return {
        ...state,
        modifierList: (state.modifierList as any).map((el: any) =>
          el.id === action.payload.modifierItem.id
            ? {
                ...el,
                value: Number(action.payload.modifierItem.value).toFixed(15),
              }
            : el,
        ),
        updateModifiersLoading: false,
      };
    },
    updateModifierList(state, action) {
      state.modifierList = action.payload.list;
      state.getModifiersLoading = false;
    },
    updateEvmLoading(state, action) {
      state.updateModifiersLoading = action.payload;
    },
    updateEvm(state, action) {
      return {
        ...state,
        evmList: (state.modifierList as any).map((el: any) =>
          el.id === action.payload.evmItem.id
            ? { ...el, value: Number(action.payload.evmItem.value).toFixed(15) }
            : el,
        ),
        updateEvmLoading: false,
      };
    },
    updateEvmList(state, action) {
      state.evmList = action.payload.list;
      state.getEvmLoading = false;
    },
    updateSourcesLoading(state, action) {
      state.updateSourcesLoading = action.payload;
    },
    updateSource(state, action) {
      return {
        ...state,
        sourceList: (state.sourceList as any).map((el: any) =>
          el.id === action.payload.sourceItem.id
            ? {
                ...el,
                value: Number(action.payload.sourceItem.value).toFixed(15),
              }
            : el,
        ),
        getSourcesLoading: false,
      };
    },
    updateSourceList(state, action) {
      state.sourceList = action.payload.list;
      state.getSourcesLoading = false;
    },
    getStatisticsLoading(state, action) {
      state.getStatisticsLoading = action.payload;
    },
    getStatistics(state, action) {
      state.statistics = action.payload.list;
      state.getStatisticsLoading = false;
    },
    updateBivMix(state, action) {
      return {
        ...state,
        naicsCodeItem: { ...state.naicsCodeItem, ...action.payload },
        getNaicsLoading: false,
      };
    },
    templateLoading(state, action) {
      state.templateLoading = action.payload;
    },
    template(state) {
      state.templateLoading = false;
    },
    getRecordCsvFile(state, action) {
      state.recordReport = action.payload;
      state.templateLoading = false;
    },
    getNoteByNaicsCode(state, action) {
      state.note = action.payload;
      state.isLoading = false;
    },
    addNote(state, action) {
      state.note = action.payload.list;
      state.isLoading = false;
    },
    updateNote(state, action) {
      state.note = action.payload.list;
      state.isLoading = false;
    },
  },
});

export const fetchStaticdPdf = (data: any) => async (dispatch: any) => {
  dispatch(templateLoading(true));
  try {
    const res = await adminApi.getStatisticsReportPdf(data);
    const url = DownloadHelper.getFileUrl(res);
    dispatch(getRecordCsvFile(url));
  } catch (e) {
    dispatch(templateLoading(false));
  }
};

export const getRecordCsv = (data: any) => async (dispatch: any) => {
  dispatch(templateLoading(true));
  try {
    const res: Blob = await adminApi.getStatisticsReport(data);
    DownloadHelper.downloadFile(res, "Record Report.csv");
    dispatch(template());
  } catch (e) {
    dispatch(templateLoading(false));
  }
};

export const fetchStatistics = () => async (dispatch: any) => {
  dispatch(getStatisticsLoading(true));
  try {
    const res = await adminApi.getStatistics();
    dispatch(getStatistics(res));
  } catch (e) {
    dispatch(getStatisticsLoading(false));
  }
};

export const addNaicsCode =
  (data: IAdminState["naicsCodeItem"]) => async (dispatch: any) => {
    dispatch(addNaicsLoading(true));
    try {
      const res = await adminApi.addNaicsCode(data);
      dispatch(addNaics(res));
      dispatch(closeModal());
    } catch (e) {
      dispatch(addNaicsLoading(false));
    }
  };

export const getNaicsCode = (id: number) => async (dispatch: any) => {
  dispatch(getNaicsLoading(true));
  try {
    const res = await adminApi.getNaicsCode(id);
    dispatch(getNaics(res));
  } catch (e) {
    dispatch(getNaicsLoading(false));
  }
};

export const updateNaicsCode =
  (data: IAdminState["naicsCodeItem"], id: number) => async (dispatch: any) => {
    dispatch(updateNaicsLoading(true));
    try {
      const res = await adminApi.updateNaicsCode(data, id);
      dispatch(updateNaics(res));
      dispatch(closeModal());
    } catch (e) {
      dispatch(updateNaicsLoading(false));
    }
  };

export const deleteNaicsCode = (id: number) => async (dispatch: any) => {
  dispatch(deleteNaicsLoading(true));
  try {
    const res = await adminApi.deleteNaicsCode(id);
    dispatch(deleteNaics(res));
  } catch (e) {
    dispatch(deleteNaicsLoading(false));
  }
};

export const getNaicsCodeList = (id: number) => async (dispatch: any) => {
  dispatch(getNaicsListLoading(true));
  try {
    const res = await adminApi.getNaicsCodeList(id);
    dispatch(getNaicsList(res));
  } catch (e) {
    dispatch(getNaicsListLoading(false));
  }
};

export const fetchSector = (id: number) => async (dispatch: any) => {
  dispatch(getSectorLoading(true));
  try {
    const res = await adminApi.getSector(id);
    dispatch(getSector(res));
  } catch (e) {
    dispatch(getSectorLoading(false));
  }
};

export const fetchSectorList = () => async (dispatch: any) => {
  dispatch(getSectorListLoading(true));
  try {
    const res = await adminApi.getSectorList();
    dispatch(getSectorList(res));
  } catch (e) {
    dispatch(getSectorListLoading(false));
  }
};

export const fetchUserList = (data: IFetchList) => async (dispatch: any) => {
  dispatch(getUserListLoading(true));
  try {
    const res = await adminApi.getUserList(data);
    dispatch(getUserList(res));
    return res.list.map((item: any) => item.id);
  } catch (e) {
    dispatch(getUserListLoading(false));
    return [];
  }
};

export const getModifiersList =
  (id: number, success?: () => void) => async (dispatch: any) => {
    dispatch(getModifiersLoading(true));
    try {
      const res = await adminApi.getModifiers(id);
      dispatch(getModifiers(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(getModifiersLoading(false));
    }
  };

export const getEvmList =
  (id: number, success?: () => void) => async (dispatch: any) => {
    dispatch(getEvmLoading(true));
    try {
      const res = await adminApi.getEvm(id);
      dispatch(getEvm(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(getEvmLoading(false));
    }
  };

export const getSourcesList = (id: number) => async (dispatch: any) => {
  dispatch(getSourcesLoading(true));
  try {
    const res = await adminApi.getSources(id);
    dispatch(getSources(res));
  } catch (e) {
    dispatch(getSourcesLoading(false));
  }
};

export const updateModifiers =
  (data: IAdminState["modifierItem"], id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateModifiersLoading(true));
    try {
      const res = await adminApi.updateModifier(data, id);
      dispatch(updateModifier(res));
      if (success) {
        success();
      }
    } catch (error) {
      dispatch(updateModifiersLoading(false));
    }
  };

export const updateModifiersList = (data: any) => async (dispatch: any) => {
  dispatch(updateModifiersLoading(true));
  try {
    const res = await adminApi.updateModifierList(data);
    dispatch(updateModifierList(res));
    return res;
  } catch (error) {
    dispatch(updateModifiersLoading(false));
  }
};

export const updateEvms =
  (data: IAdminState["sourceItem"], id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateEvmLoading(true));
    try {
      const res = await adminApi.updateEvm(data, id);
      dispatch(updateEvm(res));
      if (success) {
        success();
      }
    } catch (error) {
      dispatch(updateEvmLoading(false));
    }
  };

export const updateEvmsList = (data: any) => async (dispatch: any) => {
  dispatch(updateEvmLoading(true));
  try {
    const res = await adminApi.updateEvmList(data);
    dispatch(updateEvmList(res));
    return res;
  } catch (error) {
    dispatch(updateEvmLoading(false));
  }
};

export const updateSourcesList = (data: any) => async (dispatch: any) => {
  dispatch(getSourcesLoading(true));
  try {
    const res = await adminApi.updateSourceList(data);
    dispatch(updateSourceList(res));
    return res;
  } catch (e) {
    dispatch(getSourcesLoading(false));
  }
};

export const updateSources =
  (data: IAdminState["modifierItem"], id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(getSourcesLoading(true));
    try {
      const res = await adminApi.updateSource(data, id);
      dispatch(updateSource(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(getSourcesLoading(false));
    }
  };

export const editBivMix =
  (data: IAdminState["bivMix"]) => async (dispatch: any) => {
    dispatch(getNaicsLoading(true));
    try {
      const res = await adminApi.updateBivMix(data);
      dispatch(updateBivMix(res));
    } catch (e) {
      dispatch(getNaicsLoading(false));
    }
  };

export const updateRole =
  (data: boolean, success: () => void) => async (dispatch: any) => {
    dispatch(loading(true));
    try {
      await adminApi.checkRole(data);
      success();
    } catch (e) {
      dispatch(loading(false));
    }
  };

export const getNoteByNaics = (id: number) => async (dispatch: any) => {
  dispatch(loading(true));
  try {
    const res = await adminApi.getNoteByNaicsCode(id);
    dispatch(getNoteByNaicsCode(res.noteFullItem));
  } catch (e) {
    dispatch(loading(false));
  }
};

export const createNote =
  (data: IAdminState["note"]) => async (dispatch: any) => {
    dispatch(loading(true));
    try {
      const res = await adminApi.addNote(data);
      dispatch(addNote(res));
    } catch (e) {
      dispatch(loading(false));
    }
  };

export const updateNoteById =
  (data: IAdminState["note"], id: number) => async (dispatch: any) => {
    dispatch(loading(true));
    try {
      const res = await adminApi.updateNote(data, id);
      dispatch(updateNote(res));
    } catch (e) {
      dispatch(loading(false));
    }
  };

const { actions, reducer } = adminSlice;

export const {
  addNaicsLoading,
  addNaics,
  getNaicsLoading,
  getNaics,
  updateNaicsLoading,
  updateNaics,
  deleteNaicsLoading,
  deleteNaics,
  getNaicsListLoading,
  getNaicsList,
  getSectorLoading,
  getSector,
  getSectorListLoading,
  getUserListLoading,
  getSectorList,
  getUserList,
  getModifiers,
  getModifiersLoading,
  getEvm,
  getEvmLoading,
  getSources,
  getSourcesLoading,
  updateModifier,
  updateModifiersLoading,
  updateSource,
  updateSourcesLoading,
  getStatistics,
  getStatisticsLoading,
  updateBivMix,
  loading,
  template,
  templateLoading,
  getRecordCsvFile,
  updateSourceList,
  updateModifierList,
  getNoteByNaicsCode,
  addNote,
  updateNote,
  updateEvmLoading,
  updateEvmList,
  updateEvm,
} = actions;

export default reducer;
