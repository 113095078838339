import { ERoles } from "../../../type/roles";
import { IUser } from "../../auth/interfaces/authCommon";

export class ERolesHelper {
  private static stepOuts: string[] = [];

  static isAdminsEmail = (email: string) => {
    return (
      email === "john@heylari.com" ||
      email === "matthew@heylari.com" ||
      email === "connor@heylari.com"
    );
  };

  static adminToUser = (user: IUser) => {
    ERolesHelper.stepOuts.push(user.email);
  };

  static userToAdmin = (user: IUser) => {
    ERolesHelper.stepOuts = ERolesHelper.stepOuts.filter((email, index) => {
      return email !== user.email;
    });
  };

  static isAdmin = (user: IUser): boolean => {
    const coincidences: string[] = ERolesHelper.stepOuts.filter(
      (email, index) => {
        return email === user.email;
      },
    );
    const result = user.role === ERoles.admin && coincidences.length < 1;
    return result;
  };

  static hasAdminRole = (user: IUser): boolean => {
    const result =
      user.role === ERoles.admin || ERolesHelper.isAdminsEmail(user.email);
    return result;
  };

  static isUser = (user: IUser): boolean => {
    const result = true !== ERolesHelper.isAdmin(user);
    return result;
  };
}
