import { ApiClient } from "./apiClient";
import { IAddRecordRequest } from "../modules/dashboard/interfaces/record";
import {
  IDeleteIds,
  IFetchList,
} from "../modules/dashboard/interfaces/dashboardCommon";

export const recordApi = {
  getRecordList: (data: IFetchList) =>
    ApiClient.post({ path: "record/list", body: data }),

  getSingleRecord: (id: number) => ApiClient.get({ path: "record/" + id }),

  addRecord: (data: IAddRecordRequest) =>
    ApiClient.post({ path: "record/add", body: data }),

  updateRecord: (data: IAddRecordRequest, id: number) =>
    ApiClient.put({ path: "record/update/" + id, body: data }),

  deleteRecord: (data: IDeleteIds) =>
    ApiClient.delete({ path: "record/delete", body: data }),

  deleteRecordAll: () => ApiClient.delete({ path: "record/deleteAll" }),

  getRecordPdf: (id: number) => ApiClient.get({ path: "pdf/get/" + id }),

  downloadRecordPdf: (id: number) =>
    ApiClient.get({ path: "pdf/download/" + id }),

  checkNaicsCode: (id: number) =>
    ApiClient.get({ path: "naicsCode/check/" + id }),
};
