import { AppInput } from "../../../../common/components/input/AppInput";
import { signUpFormControls } from "../../../forms/signUp";
import { AppSelect } from "../../../../common/components/select/AppSelect";
import { AppButton } from "../../../../common/components/button/AppButton";
import React, { useState } from "react";
import { EOptionsValue } from "../../../interfaces/authCommon";

const organizationTypeOptions = [
  { value: EOptionsValue.Policyholder, label: "Policyholder" },
  { value: EOptionsValue.Broker, label: "Broker" },
  { value: EOptionsValue.Insurer, label: "Insurer" },
  { value: EOptionsValue.Reinsurer, label: "Reinsurer" },
  { value: EOptionsValue.Other, label: "Other" },
];

export const SignUpStep1 = ({ form }: { form: any }) => {
  const [isOtherField, setOtherField] = useState(
    form.getFieldValue(signUpFormControls.organisationType.name) ===
      EOptionsValue.Other,
  );

  const onSelectChange = (value: any) => {
    setOtherField(value === EOptionsValue.Other);
  };

  return (
    <>
      <AppInput config={signUpFormControls.firstName} />

      <AppInput config={signUpFormControls.lastName} />

      <AppSelect
        className="organizationSelect"
        config={signUpFormControls.organisationType}
        options={organizationTypeOptions}
        onChange={onSelectChange}
      />

      {isOtherField && <AppInput config={signUpFormControls.otherType} />}

      <AppButton className="submitBtn" text="Next" htmlType="submit" />
    </>
  );
};
