import { ApiClient } from "./apiClient";
import { IZbiv } from "../modules/dashboard/interfaces/zbiv";

export const zbivApi = {
  getZbivList: (data: IZbiv) =>
    ApiClient.post({ path: "zbiv/list", body: data }),

  downloadZbivPdf: (data: IZbiv) =>
    ApiClient.post({ path: "zbiv/download", body: data }),
};
