import styles from "./Home.module.css";
import "./Home.override.css";
import React from "react";
import backgroundIcon from "../../../assets/icons/backgroundIcon.svg";
import matthewImage from "../../../assets/images/matthew.png";
import connorImage from "../../../assets/images/connor.png";
import johnImage from "../../../assets/images/john.png";
import { openModal } from "../../common/slices/modal.slice";
import { useAppDispatch } from "../../../store/hooks";
import { ContactModal } from "./ContactModal";

export const HomeWhoNeedsLari = (props: { refCurrent: any }) => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.whoNeedsSection}>
      <div className={styles.whoNeedsSectionTop} ref={props.refCurrent}>
        <img
          src={backgroundIcon}
          alt="Background Icon"
          className={styles.whoNeedsSectionBgIcon}
        />
        <h1 className={styles.newFirstTitleBlock}>Who needs LARI</h1>
        <div className={styles.whoNeedsSectionContainer}>
          <div className={styles.whoNeedsSectionOrangeWrapper}>
            <div className={styles.whoNeedsSectionOrangeTitle}>
              <div className={styles.newSecondTitle}>Insurers</div>
            </div>
            <div className={styles.whoNeedsSectionTextBlock}>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.orangeTitle}`}
                >
                  Faster risk data validation
                </div>
                <div className={styles.newMainTextSmall}>
                  Verify submission data promptly and accurately with LARI Bl
                  values and benchmarks
                </div>
              </div>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.orangeTitle}`}
                >
                  Better pricing
                </div>
                <div className={styles.newMainTextSmall}>
                  Enhance accuracy in risk pricing with reliable values and
                  exposure data
                </div>
              </div>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.orangeTitle}`}
                >
                  Precision loss estimates
                </div>
                <div className={styles.newMainTextSmall}>
                  Estimate Bl loss exposures for specific insured locations or
                  potential impact zones to allocate necessary resources quickly
                  and efficiently
                </div>
              </div>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.orangeTitle}`}
                >
                  Improved portfolio management
                </div>
                <div className={styles.newMainTextSmall}>
                  Audit existing portfolio values data and analyze location
                  exposures in real time
                </div>
              </div>
            </div>
          </div>
          <div className={styles.whoNeedsSectionBlueWrapper}>
            <div className={styles.whoNeedsSectionBlueTitle}>
              <div className={styles.newSecondTitle}>Brokers</div>
            </div>
            <div className={styles.whoNeedsSectionTextBlock}>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.blueTitle}`}
                >
                  Structure proper coverage
                </div>
                <div className={styles.newMainTextSmall}>
                  Set appropriate Bl limits to optimize coverage for clients.
                </div>
              </div>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.blueTitle}`}
                >
                  Simplified submission process
                </div>
                <div className={styles.newMainTextSmall}>
                  Minimize customer data gathering to obtain quotes faster with
                  fewer data gaps and less manual research.
                </div>
              </div>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.blueTitle}`}
                >
                  Stronger relationships
                </div>
                <div className={styles.newMainTextSmall}>
                  Leverage LARI resources to offer better advice on business
                  interruption.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.whoNeedsSectionVioletWrapper}>
            <div className={styles.whoNeedsSectionVioletTitle}>
              <div className={styles.newSecondTitle}>Policyholders</div>
            </div>
            <div className={styles.whoNeedsSectionTextBlock}>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.violetTitle}`}
                >
                  Better protection
                </div>
                <div className={styles.newMainTextSmall}>
                  Gain a better understanding of the organization's BI exposure
                  to optimize limits, deductibles and premiums.
                </div>
              </div>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.violetTitle}`}
                >
                  Support applications
                </div>
                <div className={styles.newMainTextSmall}>
                  Take control of your data. Provide reliable and transparent
                  values to back-up your submissions.
                </div>
              </div>
              <div>
                <div
                  className={`${styles.newMainTextSmallBold} ${styles.violetTitle}`}
                >
                  Improved risk profile
                </div>
                <div className={styles.newMainTextSmall}>
                  Collect more and better data to analyze direct and indirect BI
                  exposures to plan for potential interruptions
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.whoNeedsSectionButton}>
        <h1 className={styles.newFirstTitleBlock}>
          Decades of Risk Experience
        </h1>
        <div className={`${styles.newMainText} ${styles.newMainTextWidth}`}>
          <span className={styles.orangeTitle}>
            LARI is the work of a team of forensic accounting professionals with
            decades of experience
          </span>{" "}
          handling claims and performing detailed exposure valuation studies. We
          have advised carriers and Fortune 500 companies on how to evaluate
          traditional and emerging Bl risks, including cyber and supply chain,
          to accurately quantify exposures and to improve business interruption
          risk management.
        </div>
        <div className={styles.whoNeedsSectionContainerBlock}>
          <div className={styles.whoNeedsSectionBlockInfoWrapper}>
            <img
              className={styles.whoNeedsSectionImage}
              src={matthewImage}
              alt="Matthew"
            />
            <div className={styles.whoNeedsSectionTextWrapper}>
              <div
                className={`${styles.newFourthTitleBoldName} ${styles.orangeTitle}`}
              >
                Matthew Godfrey
              </div>
              <div className={styles.newFourthTitleName}>Co-founder & CEO</div>
              <div
                className={`${styles.newFourthTitleBoldName} ${styles.whiteButton}`}
                onClick={() =>
                  dispatch(
                    openModal({
                      modalContent: (
                        <ContactModal
                          name="Matthew Godfrey"
                          title="Co-founder & CEO"
                          img={matthewImage}
                        />
                      ),
                      closable: true,
                      buttonVisible: false,
                      isMain: true,
                    }),
                  )
                }
              >
                Send Email
              </div>
            </div>
          </div>
          <div className={styles.whoNeedsSectionBlockInfoWrapper}>
            <img
              className={styles.whoNeedsSectionImage}
              src={connorImage}
              alt="Connor"
            />
            <div className={styles.whoNeedsSectionTextWrapper}>
              <div
                className={`${styles.newFourthTitleBoldName} ${styles.orangeTitle}`}
              >
                Connor Dempsey
              </div>
              <div className={styles.newFourthTitleName}>Co-founder & COO</div>
              <div
                className={`${styles.newFourthTitleBoldName} ${styles.whiteButton}`}
                onClick={() =>
                  dispatch(
                    openModal({
                      modalContent: (
                        <ContactModal
                          name="Connor Dempsey"
                          title="Co-founder & COO"
                          img={connorImage}
                        />
                      ),
                      closable: true,
                      buttonVisible: false,
                      isMain: true,
                    }),
                  )
                }
              >
                Send Email
              </div>
            </div>
          </div>
          <div className={styles.whoNeedsSectionBlockInfoWrapper}>
            <img
              className={styles.whoNeedsSectionImage}
              src={johnImage}
              alt="John"
            />
            <div className={styles.whoNeedsSectionTextWrapper}>
              <div
                className={`${styles.newFourthTitleBoldName} ${styles.orangeTitle}`}
              >
                John Dempsey
              </div>
              <div className={styles.newFourthTitleName}>Senior Adviser</div>
              <div
                className={`${styles.newFourthTitleBoldName} ${styles.whiteButton}`}
                onClick={() =>
                  dispatch(
                    openModal({
                      modalContent: (
                        <ContactModal
                          name="John Dempsey"
                          title="Senior Adviser"
                          img={johnImage}
                        />
                      ),
                      closable: true,
                      buttonVisible: false,
                      isMain: true,
                    }),
                  )
                }
              >
                Send Email
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
