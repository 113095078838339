import { openModal } from "../../common/slices/modal.slice";
import { createSlice } from "@reduxjs/toolkit";
import { IAuthState } from "../interfaces/authCommon";
import { authApi } from "../../../api/auth.api";
import { emailApi } from "../../../api/email.api";
import {
  StorageService,
  EStorageKeys,
} from "../../common/utils/storage.service";
import { IEmailData } from "../../dashboard/interfaces/email";
import { DirectIds } from "../../../type/DirectIds";

const initialState: IAuthState = {
  token: "",
  isLoading: false,
  userLoading: false,
  termsLoaded: false,
  terms: "",
  user: {
    accept_terms_of_conditions: false,
    email: "",
    firstName: "",
    fullName: "",
    icon_id: "",
    id: "",
    lastName: "",
    organisation_type: "",
    role: "",
    last_calc_time: "",
    phone_number: "",
    company_name: "",
    job_title: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loading(state, action) {
      state.isLoading = action.payload;
    },
    userLoading(state, action) {
      state.userLoading = action.payload;
    },
    termsLoading(state, action) {
      state.termsLoaded = action.payload;
    },
    terms(state, action) {
      state.terms = action.payload.text;
      state.termsLoaded = false;
    },
    signIn(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoading = false;
      StorageService.storeValue(EStorageKeys.token, action.payload.token);
      StorageService.storeValue(EStorageKeys.user_id, action.payload.user.id);
    },
    signOut(state) {
      state.user = initialState.user;
      state.token = "";
      state.isLoading = false;
      StorageService.clearStorage();
    },
    getUser(state, action) {
      state.user = action.payload.userFullRespItem;
      state.userLoading = false;
    },
    uploadProfileImage(state, action) {
      state.user.icon_id = action.payload.url;
    },
  },
});

export const fetchUser = (id: number) => async (dispatch: any) => {
  dispatch(userLoading(true));
  try {
    const res = await authApi.getUser(id);
    dispatch(getUser(res));
  } catch (e) {
    dispatch(userLoading(false));
  }
};

export const authSignIn = (data: any) => async (dispatch: any) => {
  dispatch(loading(true));
  try {
    const res = await authApi.signIn(data);
    dispatch(signIn(res));
  } catch (e) {
    dispatch(loading(false));
  }
};

export const authSignOut = (success?: () => void) => async (dispatch: any) => {
  dispatch(loading(true));
  try {
    await authApi.signOut();
    dispatch(signOut());
    success && success();
  } catch (e) {
    dispatch(loading(false));
  }
};

export const authSignUp =
  (data: any, success: () => void) => async (dispatch: any) => {
    const newUserNotification =
      "New registration: \n" +
      "  Name: " +
      data.firstName +
      "\n" +
      "  Last name: " +
      data.lastName +
      "\n" +
      "  Company name: " +
      data.companyName +
      "\n" +
      "  Email: " +
      data.email +
      "\n" +
      "  Phone number: " +
      data.phoneNumber +
      "\n" +
      "  Date: " +
      new Date().toUTCString() +
      "\n";

    dispatch(loading(true));
    try {
      await authApi.signUp(data);

      const memo2: IEmailData = {
        name: data.firstName + " " + data.lastName,
        comment: newUserNotification,
        email: data.email,
        contactWith: DirectIds.DI5_NEW_USER_NOTIFY, //'connor@heylari.com' //'matthew@heylari.com'
      };
      await emailApi.sendContactMeEmail(memo2);

      dispatch(loading(false));
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent: "Your account was registered successfully",
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
      success();
    } catch (e) {
      dispatch(loading(false));
    }
  };

export const authTerms = () => async (dispatch: any) => {
  dispatch(termsLoading(true));
  try {
    const res: any = await authApi.fetchTerms();
    dispatch(terms(res));
  } catch (e) {
    dispatch(termsLoading(false));
  }
};

export const authForgotPass =
  (data: any, success: () => void) => async (dispatch: any) => {
    dispatch(loading(true));
    try {
      await authApi.forgotPassword(data);
      dispatch(loading(false));
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent:
            "A password reset link has been sent to your email address.",
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
      success();
    } catch (e) {
      dispatch(loading(false));
    }
  };

export const authResetPass =
  (data: any, success: () => void) => async (dispatch: any) => {
    dispatch(loading(true));
    try {
      await authApi.resetPassword(data);
      dispatch(loading(false));
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent: "Password changed successfully",
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
      success();
    } catch (e) {
      dispatch(loading(false));
    }
  };

const { actions, reducer } = authSlice;

export const {
  signIn,
  signOut,
  loading,
  userLoading,
  termsLoading,
  terms,
  getUser,
  uploadProfileImage,
} = actions;

export default reducer;
