import React from "react";
import { Form, Select } from "antd";
import "../../../auth/components/auth-layout/AuthLayout.override.css";
import { IAppSelect } from "../../interfaces/inputs";

const { Option } = Select;

export const AppSelect = ({
  config,
  className,
  options,
  onChange,
}: IAppSelect) => {
  return (
    <Form.Item className={className} name={config.name} rules={config.rules}>
      <Select placeholder={config.placeholder} onChange={onChange}>
        {options.map((option) => (
          <Option
            className="selectItem"
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
