import React, { useEffect } from "react";
import "./SignIn.override.css";
import "../auth-layout/AuthLayout.override.css";
import { signInFromControls } from "../../forms/signIn";
import { Button, Form } from "antd";
import { AppInput } from "../../../common/components/input/AppInput";
import { AppButton } from "../../../common/components/button/AppButton";
import { AuthLayout } from "../auth-layout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
import { ERolesHelper } from "../../../admin/utils/isAdmin.helper";
import { ERoutes } from "../../../../type/eroutes";

export const SignIn = ({ submit }: { submit: (params: any) => void }) => {
  const navigate = useNavigate();

  const { isLoading, user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (user.role) {
      if (ERolesHelper.isUser(user)) {
        navigate(ERoutes.dashboard);
      } else {
        navigate(ERoutes.adminPage);
      }
    }
  }, [navigate, user, user.role]);

  return (
    <AuthLayout
      className="equalSideLayout"
      pageTitle="Sign In"
      displayLeft={true}
      displayLeftMobile={true}
      displayRight={true}
      sideTitle="Create Account"
      sideContent="You're one step away from seeing the magic"
      sideButton={
        <Link
          data-testid="sign-up-link"
          to={ERoutes.signUp}
          className="createAccountLink"
        >
          <Button className="createAccountBtn">New to Lari</Button>
        </Link>
      }
      sideContainerClassName="sideSignIn"
    >
      <Form
        data-testid="sign-in-form"
        name="signIn"
        onFinish={submit}
        autoComplete="off"
        className="authForm"
      >
        <AppInput config={signInFromControls.email} />

        <AppInput
          config={signInFromControls.password}
          type="password"
          className="inputPassword"
        >
          <Link to={ERoutes.forgotPassword} className="forgotPassword">
            Forgot Password
          </Link>
        </AppInput>

        <AppButton
          className="submitBtn"
          text="Sign In"
          htmlType="submit"
          disabled={isLoading}
        />
      </Form>
    </AuthLayout>
  );
};
