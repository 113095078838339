import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../modules/auth/slices/auth.slice";
import modalReducer from "../modules/common/slices/modal.slice";
import recordReducer from "../modules/dashboard/slices/record.slice";
import groupReducer from "../modules/dashboard/slices/group.slice";
import profileReducer from "../modules/dashboard/slices/profile.slice";
import subscriptionReducer from "../modules/dashboard/slices/subscription.slice";
import emailReducer from "../modules/dashboard/slices/email.slice";
import portfolioReducer from "../modules/dashboard/slices/portfolio.slice";
import csvReducer from "../modules/dashboard/slices/csv.slice";
import csvRecordReducer from "../modules/dashboard/slices/csvRecord.slice";
import adminReducer from "../modules/admin/slices/admin.slice";
import zbivReducer from "../modules/dashboard/slices/zbiv.slice";
import customExpenseReducer from "../modules/dashboard/slices/customExpense.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    record: recordReducer,
    group: groupReducer,
    profile: profileReducer,
    subscription: subscriptionReducer,
    email: emailReducer,
    csv: csvReducer,
    csvRecord: csvRecordReducer,
    admin: adminReducer,
    portfolio: portfolioReducer,
    zbiv: zbivReducer,
    customExpense: customExpenseReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
