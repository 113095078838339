import React, { useEffect } from "react";
import styles from "./Terms.module.css";
import "./Terms.override.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { authTerms } from "../../slices/auth.slice";
import { AppSpinner } from "../../../common/components/spinner/AppSpinner";
import { Form } from "antd";
import { AppCheckbox } from "../../../common/components/checkbox/AppCheckbox";
import { signUpFormControls } from "../../forms/signUp";
import { TextOfTerms } from "./TextOfTerms";

export const Terms = (props: {
  onClose?: () => void;
  onScroll?: (event: any) => void;
  termsRead?: boolean;
  checked?: boolean;
  onClick?: () => void;
}) => {
  const { termsLoaded, terms } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!terms) authTerms()(dispatch);
  }, [terms, dispatch]);

  const TermsWithCheckbox = () => {
    return (
      <Form>
        <div className={styles.checkboxTerms}>
          <AppCheckbox
            config={signUpFormControls.acceptTermsOfConditions}
            className="inputCheckbox"
            disabled={!props.termsRead}
            onClick={props.onClick}
            checked={props.checked}
          >
            <div>
              <div className="termsShow">
                Accept Terms and Conditions, Terms of Service and Terms of Use
              </div>
              <div className="termsPromt">
                Please read to the end of Terms and Conditions, Terms of Service
                and Terms of Use
              </div>
            </div>
          </AppCheckbox>
        </div>
      </Form>
    );
  };

  return (
    <div className={styles.termsWrapper}>
      <div className={styles.termsContainer}>
        <div className="termsTitle">
          <span>Terms and Conditions</span>
          <button className="close" onClick={props.onClose} />
        </div>
        <div className={styles.termsContent} onScroll={props.onScroll}>
          {termsLoaded ? <AppSpinner /> : <TextOfTerms />}
        </div>
        {termsLoaded ? <AppSpinner /> : <TermsWithCheckbox />}
      </div>
    </div>
  );
};
