import React from "react";
import styles from "./NotFound.module.css";

export const NotFoundPage = () => {
  return (
    <div className={styles.wrapper}>
      <h1>404</h1>
      <p>page not found</p>
    </div>
  );
};
