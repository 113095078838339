import { ISignUpRequest } from "../interfaces/authCommon";

export const mapSignUpRequest = (data: any): ISignUpRequest => {
  return {
    acceptTermsOfConditions: data.acceptTermsOfConditions,
    companyName: data.companyName || "",
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    jobTitle: data.jobTitle || "",
    organisationType: data.organisationType,
    otherType: data.otherType || "",
    password: data.password,
    phoneNumber: data.phoneNumber || "",
  };
};
