import { createSlice } from "@reduxjs/toolkit";
import { IModalState } from "../../auth/interfaces/authCommon";

const initialState: IModalState = {
  isOpened: false,
  modalContent: "",
  modalTitle: "",
  closable: false,
  buttonLabel: "",
  buttonVisible: false,
  isMain: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(state, action) {
      state.isOpened = true;
      state.modalContent = action.payload.modalContent;
      state.modalTitle = action.payload.modalTitle;
      state.closable = action.payload.closable;
      state.buttonLabel = action.payload.buttonLabel;
      state.buttonVisible = action.payload.buttonVisible;
      state.isMain = action.payload.isMain;
    },
    closeModal(state) {
      state.isOpened = false;
    },
  },
});

const { actions, reducer } = modalSlice;

export const { openModal, closeModal } = actions;

export default reducer;
