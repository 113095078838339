import React from "react";
import { Form, Input, Tooltip } from "antd";
import { IAppInput } from "../../interfaces/inputs";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";

const { TextArea } = Input;

export const AppInput = ({
  config,
  children,
  className,
  type = "text",
  suffix,
  title,
  onChange,
  value,
}: IAppInput) => {
  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Form.Item className={className}>
      <Form.Item
        name={config.name}
        rules={config.rules}
        validateTrigger="onBlur"
        noStyle={true}
      >
        {type === "password" ? (
          <Input.Password
            placeholder={config.placeholder}
            visibilityToggle={false}
            maxLength={config.maxLength}
          />
        ) : type === "textarea" ? (
          <TextArea
            placeholder={config.placeholder}
            maxLength={config.maxLength}
          />
        ) : (
          <label>
            {className?.includes("icon") && (
              <Tooltip className="tooltip" placement="bottom" title={title}>
                <InfoIcon className={className} />
              </Tooltip>
            )}
            <Input
              placeholder={config.placeholder}
              suffix={suffix}
              maxLength={config.maxLength}
              onChange={handleChange}
              value={value}
            />
          </label>
        )}
      </Form.Item>
      {children}
    </Form.Item>
  );
};
