import React, { useEffect } from "react";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks";
import "./AppHeader.override.css";
import { closeModal } from "../../slices/modal.slice";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import styles from "../../../home/components/Home.module.css";
import { ERoutes } from "../../../../type/eroutes";

export const AppMenuHome = (props: {
  mode: "horizontal" | "vertical" | "inline";
  hideOnClick: () => void;
  scrollToParagraph?: (name: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const menuElements = [
    {
      key: "/whatIsLari",
      url: ERoutes.home,
      label: "What is LARI",
    },
    {
      key: "/meetLari",
      url: ERoutes.home,
      label: "Meet LARI",
    },
    {
      key: "/whoNeedsLari",
      url: ERoutes.home,
      label: "Who Needs LARI",
    },
    {
      key: "/contact",
      url: ERoutes.home,
      label: "Contact Us",
    },
    {
      key: "/signUp",
      url: ERoutes.signUp,
      label: "Sign Up",
    },
    {
      key: "/signIn",
      url: ERoutes.signIn,
      label: "Sign In",
    },
  ];

  const className = (element: any) =>
    `${styles.newMainText} ${
      element.key === "/signUp" ? styles.signUpBtn : ""
    } ${element.key === "/signIn" ? styles.signInBtn : ""}`;

  return (
    <Menu
      className="headerMenuHome"
      mode={props.mode}
      defaultSelectedKeys={["/"]}
      selectedKeys={["/" + location.pathname.split("/")[1]]}
    >
      {menuElements.map((element) => (
        <Menu.Item
          key={element.key}
          className={"headerMenuElementHome"}
          onClick={() => props.hideOnClick()}
        >
          {element.key !== "/signUp" && element.key !== "/signIn" ? (
            <Link
              to={element.url}
              className={styles.newMainText}
              smooth
              onClick={() =>
                props.scrollToParagraph && props.scrollToParagraph(element.key)
              }
            >
              {element.label}
            </Link>
          ) : (
            <RouterLink to={element.url} className={className(element)}>
              {element.label}
            </RouterLink>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );
};
