import { ApiClient } from "./apiClient";
import { IUserModel } from "../modules/dashboard/interfaces/profile";
import {
  IDemoSubscriptionParams,
  IPaymentParams,
  ISubscriptionParams,
} from "../modules/dashboard/interfaces/subscription";

export const profileApi = {
  uploadAvatar: (data: FormData) =>
    ApiClient.post({ path: "file/image/", body: data, file: true }),

  updateProfile: (data: IUserModel) =>
    ApiClient.put({ path: "profile/update", body: data }),

  updatePassword: (pass: string) =>
    ApiClient.put({ path: "profile/update/password?password=" + pass }),

  getSubscriptions: () => ApiClient.get({ path: "subscription" }),

  addSubscription: (data: ISubscriptionParams) =>
    ApiClient.post({ path: "subscription/", body: data }),

  updateSubscription: (data: ISubscriptionParams, id: number) =>
    ApiClient.put({ path: "subscription/update/" + id, body: data }),

  checkSubscription: (id: number) =>
    ApiClient.get({ path: "subscription/check/" + id }),

  checkPriceForSubscription: (data: ISubscriptionParams) =>
    ApiClient.post({ path: "subscription/price/", body: data }),

  paymentCharge: (data: IPaymentParams) =>
    ApiClient.post({ path: "payment/charge", body: data }),

  applyDemoSubscription: (data: IDemoSubscriptionParams) =>
    ApiClient.post({ path: "subscription/applyDemo/", body: data }),
};
