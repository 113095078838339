import styles from "./Home.module.css";
import "./Home.override.css";
import { Form } from "antd";
import { AppInput } from "../../common/components/input/AppInput";
import { contactUsFormControls } from "../../dashboard/forms/contactUs";
import { AppButton } from "../../common/components/button/AppButton";
import React from "react";
import logoIcon from "../../../assets/icons/Logo-contact.svg";
import { useAppDispatch } from "../../../store/hooks";
import { sendContactUsEmail } from "../../dashboard/slices/email.slice";

export const HomeContactForm = (props: { refCurrent: any }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    // console.log("Success:", values);
    dispatch(
      sendContactUsEmail(
        values.comment,
        values.email,
        values.name,
        values.attachment?.file || null,
      ),
    );
    form.resetFields();
  };

  return (
    <div
      className={styles.contactSection}
      id="contact-form"
      ref={props.refCurrent}
    >
      <img
        src={logoIcon}
        alt="Main Icon"
        className={styles.contactSectionIcon}
      />
      <div className={styles.contactSectionRight}>
        <h1 className={`${styles.newFirstTitle} ${styles.contactTitle}`}>
          Contact Us
        </h1>
        <div
          className={`${styles.newThirdTitleTextContact} ${styles.contactTitle}`}
        >
          Contact us to learn how our one-of-a-kind tools and data insights help
          make better insurance decisions.
        </div>
        <Form
          name="contact-us"
          onFinish={handleSubmit}
          className="contactForm contactFormHome"
          form={form}
        >
          <AppInput config={contactUsFormControls.name} />
          <AppInput config={contactUsFormControls.email} />
          <AppInput config={contactUsFormControls.comment} />
          <AppButton
            text="Submit"
            className="contactBtnHome"
            htmlType="submit"
          />
        </Form>
      </div>
    </div>
  );
};
