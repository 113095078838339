import { IEmailData } from "../modules/dashboard/interfaces/email";
import { ApiClient } from "./apiClient";

export const emailApi = {
  sendContactUsEmail: (
    comment: string,
    email: string,
    name: string,
    data: FormData,
  ) =>
    ApiClient.post({
      path:
        "email/info?comment=" + comment + "&email=" + email + "&name=" + name,
      body: data,
      file: true,
    }),

  sendContactMeEmail: (data: IEmailData) =>
    ApiClient.post({
      path:
        "email/directContact?comment=" +
        data.comment +
        "&contactWith=" +
        data.contactWith +
        "&email=" +
        data.email +
        "&name=" +
        data.name,
      body: data,
    }),
};
