import styles from "./Home.module.css";
import "./Home.override.css";
import React from "react";
import graphIcon from "../../../assets/icons/Union.svg";
import costsIcon from "../../../assets/icons/Union (2).svg";
import keyIcon from "../../../assets/icons/Union (1).svg";
import ideaIcon from "../../../assets/icons/Union (3).svg";
import supportIcon from "../../../assets/icons/Union (4).svg";
import backgroundIcon from "../../../assets/icons/backgroundImage.svg";
import startIcon from "../../../assets/icons/startIcon.svg";

export const HomeMeetLari = (props: { refCurrent: any }) => {
  return (
    <div className={styles.meetLariSection} ref={props.refCurrent}>
      <img
        src={backgroundIcon}
        alt="Background Icon"
        className={styles.meetLariBgIcon}
      />
      <h1 className={styles.newFirstTitleBlock}>
        Meet LARI: Calculate BI Values in Minutes
      </h1>
      <div className={styles.meetLariContainer}>
        <div className={styles.meetLariBlockWrapper}>
          <div className={styles.meetLariItemWrapper}>
            <div className={styles.meetLariImageWrapper}>
              <img
                src={graphIcon}
                alt="Graph Icon"
                className={styles.meetLariImage}
              />
            </div>
            <div className={styles.newFourthTitle}>
              <span
                className={`${styles.newFourthTitleBold} ${styles.orangeTitle}`}
              >
                LARI provides valuable BI value and exposure data
              </span>{" "}
              that improve efficiency and insurance decision-making. The LARI
              model calculates ratable annual BI values, which are crucial for
              validating submissions and structuring coverage.
            </div>
          </div>
          <div className={styles.meetLariItemWrapper}>
            <div className={styles.meetLariImageWrapper}>
              <img
                src={ideaIcon}
                alt="Idea Icon"
                className={styles.meetLariImage}
              />
            </div>
            <div className={styles.newFourthTitle}>
              <span
                className={`${styles.newFourthTitleBold} ${styles.orangeTitle}`}
              >
                LARI is a user-friendly solution requiring only two key inputs:
              </span>{" "}
              <br></br>• NAICS code (industry classification) <br></br>• Annual
              revenues
            </div>
          </div>
        </div>
        <div className={styles.meetLariBlockWrapper}>
          <div className={styles.meetLariItemWrapper}>
            <div className={styles.meetLariImageWrapper}>
              <img
                src={costsIcon}
                alt="Costs Icon"
                className={styles.meetLariImage}
              />
            </div>
            <div className={styles.newFourthTitle}>
              <span
                className={`${styles.newFourthTitleBold} ${styles.orangeTitle}`}
              >
                LARI considers and quantifies variable costs for each industry.
              </span>{" "}
              Specific risk factors identify what portion of revenue may be
              subject to loss and which expenses are saved following a stoppage.
              Using public and proprietary data collected by LARI, industry
              benchmark values are calculated, enhancing validation and
              analysis.
            </div>
          </div>
          <div className={styles.meetLariItemWrapper}>
            <div className={styles.meetLariImageWrapper}>
              <img
                src={startIcon}
                alt="Star Icon"
                className={styles.meetLariImage}
              />
            </div>
            <div className={styles.newFourthTitle}>
              <span
                className={`${styles.newFourthTitleBold} ${styles.orangeTitle}`}
              >
                LARI support team is available to validate results,
              </span>{" "}
              by comparing system outputs to financial statements, tax returns
              or claims data.
            </div>
          </div>
        </div>
        <div className={styles.meetLariBlockWrapper}>
          <div className={styles.meetLariItemWrapper}>
            <div className={styles.meetLariImageWrapper}>
              <img
                src={keyIcon}
                alt="Key Icon"
                className={styles.meetLariImage}
              />
            </div>
            <div className={styles.newFourthTitle}>
              <span
                className={`${styles.newFourthTitleBold} ${styles.orangeTitle}`}
              >
                LARI provides other key insights
              </span>{" "}
              such as estimated coverage for ordinary payroll and an exposure
              value matrix to quantify Bl exposures (MFLs) for each business.
            </div>
          </div>
          <div className={styles.meetLariItemWrapper}>
            <div className={styles.meetLariImageWrapper}>
              <img
                src={supportIcon}
                alt="Support Icon"
                className={styles.meetLariImage}
              />
            </div>
            <div className={styles.newFourthTitle}>
              <span
                className={`${styles.newFourthTitleBold} ${styles.orangeTitle}`}
              >
                LARI easily assesses reported values for an individual business
                or an entire portfolio.
              </span>{" "}
              Aggregate industry exposures are available for deeper analysis.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
