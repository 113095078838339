import { IFormControl } from "../../common/interfaces/inputs";

export const resetPasswordFormControls: {
  password: IFormControl;
  confirmPassword: IFormControl;
} = {
  password: {
    rules: [
      {
        required: true,
        message: "Please input your password",
      },
      {
        min: 2,
        message: "Password must contain at least 2 characters",
      },
    ],
    name: "password",
    placeholder: "New password",
  },
  confirmPassword: {
    rules: [
      {
        required: true,
        message: "Please confirm your password",
      },
      {
        min: 2,
        message: "Password must contain at least 2 characters",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The two passwords that you entered do not match"),
          );
        },
      }),
    ],
    dependencies: "password",
    name: "confirmPassword",
    placeholder: "Confirm password",
  },
};
