import { createSlice } from "@reduxjs/toolkit";
import { profileApi } from "../../../api/profile.api";
import { openModal } from "../../common/slices/modal.slice";
import {
  IPaymentParams,
  ISubscriptionParams,
  ISubscriptionState,
} from "../interfaces/subscription";

const initialState: ISubscriptionState = {};

export const profileSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    getSubscriptionLoading(state, action) {
      state.getSubscriptionLoading = action.payload;
    },
    getSubscription(state, action) {
      state.subscription = action.payload;
      state.getSubscriptionLoading = false;
    },
    addSubscriptionLoading(state, action) {
      state.addSubscriptionLoading = action.payload;
    },
    addSubscription(state, action) {
      state.subscription = action.payload;
      state.addSubscriptionLoading = false;
    },
    updateSubscriptionLoading(state, action) {
      state.updateSubscriptionLoading = action.payload;
    },
    updateSubscription(state, action) {
      state.subscription = action.payload;
      state.updateSubscriptionLoading = false;
    },
    checkSub(state, action) {
      state.subscriptionStatus = action.payload;
    },
    paymentChargeLoading(state, action) {
      state.paymentChargeLoading = action.payload;
    },
    paymentCharge(state) {
      state.paymentChargeLoading = false;
    },
  },
});

export const checkSubscription = (id: number) => async (dispatch: any) => {
  dispatch(getSubscriptionLoading(true));
  try {
    const res = await profileApi.checkSubscription(id);
    dispatch(checkSub(res));
  } catch (e) {
    dispatch(getSubscriptionLoading(false));
  }
};

export const fetchSubscription = () => async (dispatch: any) => {
  dispatch(getSubscriptionLoading(true));
  try {
    const res = await profileApi.getSubscriptions();
    dispatch(getSubscription(res));
  } catch (e) {
    dispatch(getSubscriptionLoading(false));
  }
};

export const checkPriceSubscription =
  (data: ISubscriptionParams, success?: (res: any) => void) =>
  async (dispatch: any) => {
    dispatch(addSubscriptionLoading(true));
    try {
      const res = await profileApi.checkPriceForSubscription(data);
      if (success) {
        success(res.price);
      }
    } catch (e) {
      dispatch(addSubscriptionLoading(false));
    }
  };

export const createSubscription =
  (data: ISubscriptionParams, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(addSubscriptionLoading(true));
    try {
      const res = await profileApi.addSubscription(data);
      dispatch(addSubscription(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(addSubscriptionLoading(false));
    }
  };

export const editSubscription =
  (data: ISubscriptionParams, id: number, success: () => void) =>
  async (dispatch: any) => {
    dispatch(updateSubscriptionLoading(true));
    try {
      const res = await profileApi.updateSubscription(data, id);
      dispatch(updateSubscription(res));
      success();
    } catch (e) {
      dispatch(updateSubscriptionLoading(false));
    }
  };

export const chargePayment =
  (data: IPaymentParams) => async (dispatch: any) => {
    dispatch(paymentChargeLoading(true));
    try {
      await profileApi.paymentCharge(data);
      dispatch(paymentCharge());
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent: "Your credit count has been updated. ",
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
    } catch (e) {
      dispatch(paymentChargeLoading(false));
    }
  };

const { actions, reducer } = profileSlice;

export const {
  getSubscription,
  getSubscriptionLoading,
  addSubscriptionLoading,
  addSubscription,
  paymentChargeLoading,
  updateSubscriptionLoading,
  paymentCharge,
  updateSubscription,
  checkSub,
} = actions;

export default reducer;
