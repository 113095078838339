import { ApiClient } from "./apiClient";
import {
  IAddIds,
  IDeleteIds,
  IFetchList,
} from "../modules/dashboard/interfaces/dashboardCommon";
import { IAddGroup } from "../modules/dashboard/interfaces/group";

export const groupApi = {
  getGroupList: (data: IFetchList) =>
    ApiClient.post({ path: "group/list", body: data }),

  getSingleGroup: (id: number) => ApiClient.get({ path: "group/" + id }),

  getRecordsInGroup: (id: number, data: IFetchList) =>
    ApiClient.post({ path: "group/record/" + id + "/list", body: data }),

  deleteGroup: (data: IDeleteIds) =>
    ApiClient.delete({ path: "group/delete", body: data }),

  addGroup: (data: IAddGroup) =>
    ApiClient.post({ path: "group/add", body: data }),

  addRecordsToGroup: (data: IAddIds, id: number) =>
    ApiClient.post({ path: "group/record/" + id + "/add", body: data }),

  deleteRecordsFromGroup: (data: IDeleteIds, id: number) =>
    ApiClient.delete({ path: "group/record/" + id + "/delete", body: data }),

  updateGroup: (data: IAddGroup, id: number) =>
    ApiClient.put({ path: "group/update/" + id, body: data }),

  getGroupPdf: (id: number) => ApiClient.get({ path: "pdf/get/group/" + id }),

  downloadGroupPdf: (id: number) =>
    ApiClient.get({ path: "pdf/download/group/" + id }),
};
