import React, { useState } from "react";
import styles from "../../common/components/header/AppHeader.module.css";
import { Button, Grid, Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import logoDesktop from "../../../assets/icons/BetaLogo.svg";
import logoBlue from "../../../assets/images/logoMobile.png";
import "../../common/components/header/AppHeader.override.css";
import { AppMenuHome } from "../../common/components/header/AppMenuHome";
import { ERoutes } from "../../../type/eroutes";

const { Header } = Layout;
const { useBreakpoint } = Grid;

export const HomeHeader = (props: {
  transparent: boolean;
  scrollToParagraph?: (name: string) => void;
  className?: string;
}) => {
  const [visible, setVisible] = useState(false);
  const { xl } = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();

  const isProfile = () => {
    return location.pathname.valueOf() === ERoutes.profile;
  };

  const isHome = () => {
    return location.pathname.valueOf() === ERoutes.home;
  };

  const burgerMenuClass = (): string => {
    let menuBtnClass = "barsMenu";
    if (visible) {
      menuBtnClass += " barsOpen";
    }
    if (isProfile()) {
      menuBtnClass += " colored";
    }
    return menuBtnClass;
  };

  return (
    <Header
      className={
        props.transparent ? "headerTransparent" : `header ${props.className}`
      }
    >
      <div className={styles.headerContent}>
        {isProfile() ? (
          <div
            className={styles.logo}
            onClick={() => {
              navigate(ERoutes.dashboard);
            }}
          >
            <img className={styles.logoImg} src={logoBlue} alt="Hey Lari" />
            <span className={styles.logoTitleBlue}>LARI</span>
          </div>
        ) : (
          <div
            className={styles.logo}
            onClick={() => {
              navigate(ERoutes.dashboard);
            }}
          >
            <img className={styles.logoImg} src={logoDesktop} alt="Hey Lari" />
            {/* <span className={styles.logoTitle}>LARI</span> */}
          </div>
        )}
        {isHome() ? (
          xl ? (
            <AppMenuHome
              mode="horizontal"
              hideOnClick={() => setVisible(false)}
              scrollToParagraph={(name) =>
                props.scrollToParagraph && props.scrollToParagraph(name)
              }
            />
          ) : (
            <>
              <Button
                className={burgerMenuClass()}
                type="primary"
                onClick={() => setVisible(!visible)}
              >
                <div className="bar1" />
                <div className="bar2" />
                <div className="bar3" />
              </Button>
              <div className={visible ? "mobileMenu open" : "mobileMenu"}>
                <AppMenuHome
                  mode="inline"
                  hideOnClick={() => setVisible(false)}
                  scrollToParagraph={(name) =>
                    props.scrollToParagraph && props.scrollToParagraph(name)
                  }
                />
              </div>
            </>
          )
        ) : xl ? (
          <AppMenuHome
            mode="horizontal"
            hideOnClick={() => setVisible(false)}
            scrollToParagraph={(name) =>
              props.scrollToParagraph && props.scrollToParagraph(name)
            }
          />
        ) : (
          <>
            <Button
              className={burgerMenuClass()}
              type="primary"
              onClick={() => setVisible(!visible)}
            >
              <div className="bar1" />
              <div className="bar2" />
              <div className="bar3" />
            </Button>
            <div className={visible ? "mobileMenu open" : "mobileMenu"}>
              <AppMenuHome
                mode="inline"
                hideOnClick={() => setVisible(false)}
                scrollToParagraph={(name) =>
                  props.scrollToParagraph && props.scrollToParagraph(name)
                }
              />
            </div>
          </>
        )}
      </div>
    </Header>
  );
};
