import {
  IDeleteIds,
  IFetchList,
  IUploadImportIds,
  IUploadImportWithGroup,
} from "../modules/dashboard/interfaces/dashboardCommon";
import { ApiClient } from "./apiClient";

export const csvApi = {
  importRecord: (data: FormData) =>
    ApiClient.post({
      path: "csv/importRecord",
      body: data,
      file: true,
    }),

  importBatchRecords: (data: FormData) =>
    ApiClient.post({
      path: "csv/importBatchRecords",
      body: data,
      file: true,
    }),

  deleteBatchUploads: (data: IDeleteIds) =>
    ApiClient.delete({ path: "csv/delete", body: data }),

  getSingleBatchUpload: (id: number) => ApiClient.get({ path: "csv/" + id }),

  getBatchUploadsInProgress: () => ApiClient.get({ path: "csv/getInProgress" }),

  importBatchUploads: (data: IUploadImportWithGroup) =>
    ApiClient.post({ path: "csv/import", body: data }),

  deleteRecordsFromBatchUpload: (data: IDeleteIds, id: number) =>
    ApiClient.delete({ path: "csv/record/" + id + "/delete", body: data }),

  importOfBatchUpload: (data: IUploadImportIds, id: number) =>
    ApiClient.post({ path: "csv/record/" + id + "/import", body: data }),

  getBatchUploadList: (data: IFetchList) =>
    ApiClient.post({ path: "csv/list", body: data }),

  getRecordsInBatchUpload: (id: number, data: IFetchList) =>
    ApiClient.post({ path: "csv/record/" + id + "/list", body: data }),

  getSingleBatchUploadRecord: (id: number) =>
    ApiClient.get({ path: "csv/record/" + id }),

  getTemplate: () => ApiClient.get({ path: "csv/template" }),

  getNewBatchTemplate: () => ApiClient.get({ path: "csv/batchTemplate2" }),
};
