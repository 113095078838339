import { IFormControl } from "../../common/interfaces/inputs";
import { Regexp } from "../../common/utils/regex.helper";

export const contactOneFormControls: {
  name: IFormControl;
  email: IFormControl;
  comment: IFormControl;
} = {
  name: {
    rules: [
      {
        required: true,
        message: "Please enter your name",
      },
      {
        pattern: Regexp.nameInput,
        message: "Please enter a valid name",
      },
    ],
    name: "name",
    placeholder: "Your Name",
    maxLength: 20,
  },
  email: {
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail",
      },
      {
        required: true,
        message: "Please input your E-mail",
      },
    ],
    name: "email",
    placeholder: "Your Mail",
    maxLength: 100,
  },
  comment: {
    rules: [{ required: true, message: "Please enter your comment" }],
    name: "comment",
    placeholder: "Write to me here",
    maxLength: 200,
  },
};
