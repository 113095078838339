import React from "react";
import "./AppModal.override.css";
import { Button, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { closeModal } from "../../slices/modal.slice";

export const AppModal = (props: { location: string }) => {
  const {
    isOpened,
    modalContent,
    modalTitle,
    closable,
    buttonLabel,
    buttonVisible,
    isMain,
  } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  return (
    <Modal
      // visible={isOpened}
      open={isOpened}
      footer={null}
      centered={true}
      closable={closable}
      className={
        props.location === "/" && isMain ? "contactModal" : "infoModal"
      }
      onCancel={() => {
        dispatch(closeModal());
      }}
    >
      {!isMain && <h2 className="infoModalTitle">{modalTitle}</h2>}
      <div className="infoModalContent">{modalContent}</div>
      {buttonVisible && (
        <Button
          key="submit"
          type="default"
          onClick={() => {
            dispatch(closeModal());
          }}
          className="infoModalClose"
        >
          {buttonLabel}
        </Button>
      )}
    </Modal>
  );
};
