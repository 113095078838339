import React from "react";
import { Spin } from "antd";
import styles from "./AppSpinner.module.css";

export const AppSpinner = () => {
  return (
    <div className={styles.spinnerWrapper}>
      <Spin />
    </div>
  );
};
