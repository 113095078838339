import styles from "../Home.module.css";
import "../Home.override.css";
import "animate.css";
import { useEffect, useState } from "react";

export const SlideComponent = () => {
  const [slide, setSlide] = useState(1);
  const slides = [
    {
      top: (
        <h1 className={`${styles.whatIsLariTitle}`}>
          Better{" "}
          <span className={styles.whatIsLariOrangeTitle}>BI insights.</span>
        </h1>
      ),
      bottom: (
        <h1 className={`${styles.whatIsLariTitle}`}>
          Smarter<br></br>
          <span className={styles.whatIsLariOrangeTitle}>
            BI insurance decisions
          </span>
        </h1>
      ),
    },
    {
      top: (
        <h1 className={`${styles.whatIsLariTitle}`}>
          Improve <span className={styles.whatIsLariOrangeTitle}>coverage</span>
        </h1>
      ),
      bottom: (
        <h1 className={`${styles.whatIsLariTitle}`}>
          Validate<br></br>
          <span className={styles.whatIsLariOrangeTitle}>submitted values</span>
        </h1>
      ),
    },
    {
      top: (
        <h1 className={`${styles.whatIsLariTitle}`}>
          Structure<br></br>
          <span className={styles.whatIsLariOrangeTitle}>proper limits</span>
        </h1>
      ),
      bottom: (
        <h1 className={`${styles.whatIsLariTitle}`}>
          Increase{" "}
          <span className={styles.whatIsLariOrangeTitle}>transparency</span>
        </h1>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((prevSlide) => (prevSlide % 3) + 1);
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.textAnimation}>
      {slides.map((slideContent, index) => (
        <div key={index} className={styles.slideContent}>
          <div
            className={`${styles.topText} ${
              index + 1 === slide ? styles.showTop : ""
            }`}
          >
            {slideContent.top}
          </div>
          <div
            className={`${styles.bottomText} ${
              index + 1 === slide ? styles.showBottom : ""
            }`}
          >
            {slideContent.bottom}
          </div>
        </div>
      ))}
    </div>
  );
};
