import React, { useEffect } from "react";
import styles from "./Auth.module.css";
import { SignIn } from "./sign-in/SignIn";
import { IAuthTypes } from "../interfaces/authTypes";
import { ResetPassword } from "./reset-password/ResetPassword";
import { ForgotPassword } from "./forgot-password/ForgotPassword";
import { SignUp } from "./sign-up/SignUp";
import {
  authForgotPass,
  authResetPass,
  authSignIn,
  authSignUp,
} from "../slices/auth.slice";
import { useAppDispatch } from "../../../store/hooks";
import { getToken } from "../../common/utils/storage.service";
import { useNavigate } from "react-router-dom";
import { ERoutes } from "../../../type/eroutes";

export const AuthIndex = ({ type }: IAuthTypes) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getToken()) {
      navigate(ERoutes.dashboard);
    }
  }, [navigate]);

  const authComponentsMap = {
    signIn: <SignIn submit={(params) => authSignIn(params)(dispatch)} />,
    signUp: (
      <SignUp
        submit={(params, success) => authSignUp(params, success)(dispatch)}
      />
    ),
    forgotPass: (
      <ForgotPassword
        submit={(params, success) => authForgotPass(params, success)(dispatch)}
      />
    ),
    resetPass: (
      <ResetPassword
        submit={(params, success) => authResetPass(params, success)(dispatch)}
      />
    ),
  };

  return <div className={styles.authWrapper}>{authComponentsMap[type]}</div>;
};
