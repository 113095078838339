import { Link as RouterLink } from "react-router-dom";
import styles from "./Home.module.css";
import React from "react";
import { AppVideo } from "../../common/components/video/AppVideo";
import lineIcon from "../../../assets/icons/LineForHome.svg";
import { ERoutes } from "../../../type/eroutes";

export const HomeTopSection = (props: { refCurrent: any }) => {
  return (
    <div className={styles.topSection} ref={props.refCurrent}>
      <h1 className={styles.newFirstTitleBlock}>
        Proprietary Business Interruption Value Modeling
      </h1>
      <div className={styles.topSectionContainer}>
        <div className={styles.topSectionLeft}>
          <div
            className={`${styles.textAlign} ${styles.newFourthTitleBold} ${styles.orangeTitle}`}
          >
            LARI is an innovative tool built by experts to allow businesses,
            carriers, reinsurers and modelers to assess and quantify business
            interruption (BI) risks with confidence.
          </div>
          <img
            src={lineIcon}
            alt="Line Icon"
            className={styles.topSectionLine}
          />
          <div className={`${styles.textAlign} ${styles.newFourthTitle}`}>
            Calculate business interruption values (BIV) automatically in
            minutes. Sign Up to get confidence in submitted values and coverage
            limits.
          </div>
          <div className={styles.topSectionBtns}>
            {/* <Link to={ERoutes.signUp} className={styles.signUpBtnHome}>
              Sign Up
            </Link> */}
            <RouterLink
              to={ERoutes.signUp}
              className={`${styles.newMainText} ${styles.signUpBtn}`}
            >
              Sign Up
            </RouterLink>
          </div>
        </div>
        <div className={styles.topSectionRight}>
          <AppVideo url="https://heylari-image-dev.s3.amazonaws.com/website_content/LARI+explainer+FINAL+CUT.mp4" />
        </div>
      </div>
    </div>
  );
};
