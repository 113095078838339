import React from "react";
import styles from "./AppCookieBanner.module.css";
import "./AppCookieBanner.override.css";
import { AppButton } from "../button/AppButton";
import { useCookies } from "react-cookie";

export const AppCookieBanner = () => {
  const [cookies, setCookie] = useCookies(["CookieConsent"]);

  const handleCookiesAccept = () => {
    setCookie("CookieConsent", true, { path: "/" });
  };

  return (
    <>
      {cookies.CookieConsent === undefined && (
        <div className={styles.cookieBanner}>
          <div className={styles.cookieBannerText}>
            “This website uses cookies in order to offer you the most relevant
            information. Please accept cookies for optimal performance”
          </div>
          <div className={styles.cookieButtons}>
            <AppButton
              text="Accept"
              className="acceptBtn"
              onClick={handleCookiesAccept}
            />
          </div>
        </div>
      )}
    </>
  );
};
