import Cookies from "js-cookie";

export enum EStorageKeys {
  token = "token",
  user_id = "user_id",
}

export class StorageService {
  static storeValue(key: EStorageKeys, value: any): void {
    localStorage.setItem(key, value);
    if (key === EStorageKeys.token) Cookies.set("lariu-token", "" + value);
  }

  static getItem(key: EStorageKeys): string {
    if (key === EStorageKeys.token) {
      const tk = localStorage.getItem(EStorageKeys.token);
      Cookies.set("lariu-token", "" + tk);
      return tk || "";
    }
    return localStorage.getItem(key) || "";
  }

  static removeItem(key: EStorageKeys): void {
    localStorage.removeItem(key);
  }

  static clearStorage(): void {
    localStorage.clear();
  }
}

export const getToken = (): string => {
  return StorageService.getItem(EStorageKeys.token);
};

export const getUser = (): any => {
  return StorageService.getItem(EStorageKeys.user_id);
};
