import React from "react";
import { AppInput } from "../../../../common/components/input/AppInput";
import { signUpFormControls } from "../../../forms/signUp";
import { AppButton } from "../../../../common/components/button/AppButton";

export const SignUpStep2 = () => {
  return (
    <>
      <AppInput config={signUpFormControls.companyName} suffix="Optional" />

      <AppInput config={signUpFormControls.jobTitle} suffix="Optional" />

      <AppInput config={signUpFormControls.phoneNumber} suffix="Optional" />

      <AppButton className="submitBtn" text="Next" htmlType="submit" />
    </>
  );
};
