import { openModal } from "../../common/slices/modal.slice";
import { createSlice } from "@reduxjs/toolkit";
import {
  ICustomExpense,
  ICustomExpenseRequest,
} from "../interfaces/customExpense";
import { customExpenseApi } from "../../../api/customExpense.api";

const initialState: ICustomExpense = {};

export const customExpenseSlice = createSlice({
  name: "customExpense",
  initialState,
  reducers: {
    addCustomExpenseLoading(state, action) {
      state.addCustomExpenseLoading = action.payload;
    },
    updateCustomExpenseLoading(state, action) {
      state.updateCustomExpenseLoading = action.payload;
    },
    addCustomExpense(state, action) {
      state.custom = action.payload.customExpenseItem;
      state.addCustomExpenseLoading = false;
    },
    updateCustomExpense(state, action) {
      return {
        ...state,
        custom: { ...state.custom, ...action.payload.customExpenseItem },
        updateCustomExpenseLoading: false,
      };
    },
  },
});

export const createCustomExpense =
  (data: ICustomExpenseRequest, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(addCustomExpenseLoading(true));
    try {
      const res = await customExpenseApi.addCustomExpense(data);
      dispatch(addCustomExpense(res));
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent:
            "LARI has finished calculating custom expences for this record.",
          buttonLabel: "Close",
          closable: true,
          buttonVisible: true,
        }),
      );
      success && success();
    } catch (e) {
      dispatch(addCustomExpenseLoading(false));
    }
  };

export const editCustomExpense =
  (data: ICustomExpenseRequest, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateCustomExpenseLoading(true));
    try {
      const res = await customExpenseApi.updateCustomExpense(data);
      dispatch(updateCustomExpense(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(updateCustomExpenseLoading(false));
    }
  };

const { actions, reducer } = customExpenseSlice;

export const {
  addCustomExpenseLoading,
  addCustomExpense,
  updateCustomExpenseLoading,
  updateCustomExpense,
} = actions;

export default reducer;
