import React from "react";
import { Checkbox, Form } from "antd";
import { IAppCheckbox } from "../../interfaces/inputs";

export const AppCheckbox = ({
  config,
  className,
  children,
  disabled,
  onClick,
  checked,
}: IAppCheckbox) => {
  return (
    <Form.Item
      className={className}
      name={config.name}
      rules={config.rules}
      valuePropName={config.valuePropName}
      initialValue={checked}
    >
      <Checkbox
        className={checked ? "ant-checkbox-checked" : ""}
        disabled={disabled}
        checked={checked}
        onClick={() => onClick && onClick()}
      >
        {children}
      </Checkbox>
    </Form.Item>
  );
};
