import { ApiClient } from "./apiClient";

export const authApi = {
  signIn: (data: object) =>
    ApiClient.post({ path: "authorization", body: data, auth: false }),

  signOut: () => ApiClient.delete({ path: "authorization" }),

  signUp: (data: object) =>
    ApiClient.post({ path: "user/add", body: data, auth: false }),

  //http://77.68.6.78:8080/api/v1/authorization/forgotpassword
  forgotPassword: (data: object) =>
    ApiClient.post({
      path: "authorization/forgotpassword",
      body: data,
      auth: false,
    }),

  resetPassword: (data: object) =>
    ApiClient.put({ path: "authorization", body: data, auth: false }),

  fetchTerms: () => ApiClient.get({ path: "terms", auth: false }),

  getUser: (id: number) => ApiClient.get({ path: "user/" + id }),
};
