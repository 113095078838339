import { closeModal, openModal } from "../modules/common/slices/modal.slice";
import { signOut } from "../modules/auth/slices/auth.slice";
import { getToken } from "../modules/common/utils/storage.service";
import { getNoteByNaicsCode } from "../modules/admin/slices/admin.slice";
import { ERoutes } from "../type/eroutes";
import { env } from "../type/AppLimits";
import { openErrorMessage } from "../modules/common/components/error/ErrorMessageModal";
const globalStore = import("../store/store");
const conf = require("../config/config.json")[env];

function reqMode() {
  const mode = conf.REACT_APP_REQ_MODE;
  return mode;
}

function apiServ() {
  const hostUrl = new URL(conf.REACT_APP_API_BASE_URL);
  let protocol = hostUrl.protocol;
  if (protocol.endsWith(":"))
    protocol = protocol.substring(0, protocol.length - 1);
  let host = hostUrl.hostname;
  if (host.endsWith("/") || host.endsWith("\\"))
    host = host.substring(0, host.length - 1);
  return protocol + "://" + host;
}

async function http(
  path: string,
  config: RequestInit,
  auth: boolean,
  file: boolean,
): Promise<any> {
  const baseUrl = conf.REACT_APP_API_BASE_URL;

  const headers =
    reqMode() === "cors"
      ? {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `"${apiServ()}"`,
        }
      : {
          "Content-Type": "application/json",
        };

  const _config: any = {
    ...config,
    mode: reqMode(),
    headers: headers,
  };

  if (auth) {
    const authHeader = { Authorization: getToken() };

    _config.headers = !file
      ? Object.assign(_config.headers, authHeader)
      : authHeader;
  }

  const request = new Request(baseUrl + path, _config);

  // console.log("req " + baseUrl + path + "  " + request);

  return new Promise((resolve, reject) => {
    return fetch(request)
      .then(async (response) => {
        if (!response.ok) {
          let message = "";
          if (response.status === 401) {
            message = "Unauthorized";
          } else {
            const json = await response.json();
            message = json.errorMessage;
          }
          throw new Error(message);
        } else {
          const contentDispositionHeader = response.headers.get(
            "Content-Disposition",
          );
          let filename = null;
          if (contentDispositionHeader) {
            // Extract the filename using regular expression
            const matches = /filename[^;=\n]*=(["'])?([^";\n]*)\1?/i.exec(
              contentDispositionHeader,
            );
            if (matches) {
              filename = matches[2];
            }
          }

          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            resolve(await response.json());
          } else {
            resolve({ data: await response.blob(), filename });
          }
        }
      })
      .catch((error) => {
        if (error.message.includes("{ErrorCSV}")) {
          const errorMessageNew = error.message
            .replace(/{ErrorCSV}/gi, "")
            .replace(new RegExp("/n", "g"), "\n");
          globalStore.then(({ store }) => {
            store.dispatch(openErrorMessage(errorMessageNew));
          });
        } else if (
          error.message.includes("Note wasn't found for this NaicsCode")
        ) {
          globalStore.then(({ store }) => {
            store.dispatch(getNoteByNaicsCode(""));
            store.dispatch(
              openModal({
                modalTitle: "Add your's first note",
                modalContent: "",
                buttonLabel: "Close",
                closable: false,
                buttonVisible: true,
              }),
            );
          });
          return error;
        } else if (error.message.includes("Unauthorized")) {
          globalStore.then(({ store }) => {
            store.dispatch(closeModal());
            store.dispatch(signOut());
            setTimeout((_) => {
              window.location.href = ERoutes.signIn;
            }, 1000);
          });
          return error;
        } else {
          globalStore.then(({ store }) => {
            store.dispatch(
              openErrorMessage(error.message || "Something went wrong"),
            );
          });
        }
        reject(error);
      });
  });
}

interface IRequest {
  path: string;
  config?: RequestInit;
  body?: any;
  auth?: boolean;
  file?: boolean;
}

export class ApiClient {
  static async get({
    path,
    config,
    auth = true,
    file = false,
  }: IRequest): Promise<any> {
    const init = { method: "get", ...config };
    return await http(path, init, auth, file);
  }

  static async post({
    path,
    body,
    config,
    auth = true,
    file = false,
  }: IRequest): Promise<any> {
    const init = {
      method: "post",
      body: file ? body : JSON.stringify(body),
      ...config,
    };
    return await http(path, init, auth, file);
  }

  static async put({
    path,
    body,
    config,
    auth = true,
    file = false,
  }: IRequest): Promise<any> {
    const init = {
      method: "put",
      body: file ? body : JSON.stringify(body),
      ...config,
    };
    return await http(path, init, auth, file);
  }

  static async delete({
    path,
    body,
    config,
    auth = true,
    file = false,
  }: IRequest): Promise<any> {
    const init = { method: "delete", body: JSON.stringify(body), ...config };
    return await http(path, init, auth, file);
  }
}
