import React, { useEffect, useRef } from "react";
import "./Home.override.css";
import { Layout } from "antd";
import { AppFooter } from "../../common/components/footer/AppFooter";
import { AppCookieBanner } from "../../common/components/cookie/AppCookieBanner";
import {} from "./HomeCarousel";
import { HomeContactForm } from "./HomeContactForm";
import { HomeTopSection } from "./HomeTopSection";
import { useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeWhoNeedsLari } from "./HomeWhoNeedsLari";
import { HomeMeetLari } from "./HomeMeetLari";
import { HomeWhatIsLari } from "./HomeWhatIsLari";
import { HomeHeader } from "./HomeHeader";
import { ERolesHelper } from "../../admin/utils/isAdmin.helper";
import { ERoutes } from "../../../type/eroutes";

const { Content } = Layout;

export const HomeIndex = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user || !user.id) return;
    if (ERolesHelper.isUser(user)) {
      navigate(ERoutes.dashboard);
    } else if (ERolesHelper.isAdmin(user)) {
      navigate(ERoutes.adminPage);
    } else {
      return;
    }
  }, [navigate, user, user.role]);

  const contactRef = useRef<HTMLParagraphElement>(null);
  const whatIsLariRef = useRef<HTMLParagraphElement>(null);
  const meetLariRef = useRef<HTMLParagraphElement>(null);
  const whoNeedsLari = useRef<HTMLParagraphElement>(null);

  const scrollToParagraph = (name: string) => {
    if (name === "/whatIsLari") {
      if (whatIsLariRef.current) {
        whatIsLariRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (name === "/contact") {
      if (contactRef.current) {
        contactRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (name === "/meetLari") {
      if (meetLariRef.current) {
        meetLariRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (name === "/whoNeedsLari") {
      if (whoNeedsLari.current) {
        whoNeedsLari.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <Layout className="homeWrapper">
      <HomeHeader
        transparent={
          location.pathname === ERoutes.contactUs ||
          location.pathname === ERoutes.profile
        }
        scrollToParagraph={(name) => scrollToParagraph(name)}
        className="simpleHeader"
      />
      <Content className="contentLayout contentLayoutDashboard">
        <HomeWhatIsLari />
        <HomeTopSection refCurrent={whatIsLariRef} />
        <HomeMeetLari refCurrent={meetLariRef} />
        <HomeWhoNeedsLari refCurrent={whoNeedsLari} />
        <HomeContactForm refCurrent={contactRef} />
      </Content>
      <AppFooter />
      <AppCookieBanner />
    </Layout>
  );
};
