import { createSlice } from "@reduxjs/toolkit";
import { portfolioApi } from "../../../api/portfolio.api";
import { IPortfolioImport, IPortfolioState } from "../interfaces/portfolio";

const initialState: IPortfolioState = {};

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    getPortfolioImportStatus(state, action) {
      state.portfolioImportStatus = action.payload;
      state.fetchPortfolioImportStatus = false;
    },
    fetchPortfolioImportStatusLoading(state, action) {
      state.fetchPortfolioImportStatus = action.payload;
    },
    getPortfolioImportCSV(state, action) {
      state.portfolioImportCSV = action.payload;
      state.fetchPortfolioImportCSV = false;
    },
    clearPortfolioImportCSV(state, action) {
      state.portfolioImportCSV = undefined;
    },
    fetchPortfolioImportCSVLoading(state, action) {
      state.fetchPortfolioImportCSV = action.payload;
    },
    importPortfolioStart(state, action) {
      state.importPortfolioStartResult = action.payload;
      state.importPortfolioStartLoading = false;
    },
    importPortfolioStartLoading(state, action) {
      state.importPortfolioStartLoading = action.payload;
    },
  },
});

export const fetchPortfolioImportStatus =
  (id: string) => async (dispatch: any) => {
    dispatch(fetchPortfolioImportStatusLoading(true));
    try {
      const res = await portfolioApi.getPortfolioImportStatus(id);
      dispatch(getPortfolioImportStatus(res));
    } catch (e) {
      dispatch(fetchPortfolioImportStatusLoading(false));
    }
  };

export const fetchPortfolioImports = () => async (dispatch: any) => {
  dispatch(fetchPortfolioImportStatusLoading(true));
  try {
    const res = await portfolioApi.getPortfolioImports();
    return res;
  } catch (e) {
    dispatch(fetchPortfolioImportStatusLoading(false));
  }
};

export const fetchPortfolioImportCSV = () => async (dispatch: any) => {
  dispatch(fetchPortfolioImportCSVLoading(true));
  try {
    const res = await portfolioApi.getPortfolioImportCSV();
    dispatch(getPortfolioImportCSV(res));
  } catch (e) {
    dispatch(fetchPortfolioImportCSVLoading(false));
  }
};

export const startPortfolioImport =
  (param: IPortfolioImport) => async (dispatch: any) => {
    dispatch(importPortfolioStartLoading(true));
    try {
      const res = await portfolioApi.importPortfolioStart(param);
      dispatch(importPortfolioStart(res));
    } catch (e) {
      dispatch(importPortfolioStartLoading(false));
    }
  };

export const cleanPortfolioImportCSV = () => async (dispatch: any) => {
  dispatch(fetchPortfolioImportCSVLoading(true));
  try {
    let res;
    dispatch(clearPortfolioImportCSV(res));
  } catch (e) {
    dispatch(fetchPortfolioImportCSVLoading(false));
  }
};

const { actions, reducer } = portfolioSlice;

export const {
  fetchPortfolioImportStatusLoading,
  getPortfolioImportStatus,
  getPortfolioImportCSV,
  fetchPortfolioImportCSVLoading,
  clearPortfolioImportCSV,
  importPortfolioStartLoading,
  importPortfolioStart,
} = actions;

export default reducer;
