export const Regexp = {
  nameInput: /^[a-zA-Z\s()'-]{1,20}$/,
  textInput: /^[a-zA-Z0-9\s()'.,&-]{1,100}$/,
  descriptionInput: /^[a-zA-Z0-9\s()'.,&-]{1,200}$/,
  companyName: /^[a-zA-Z0-9\s()'.,&-]{1,100}$/,
  phoneNumber: /^[0-9+]{1,15}$/,
  naics: /^[0-9]{6}$/,
  zipCode: /^[0-9]{5}$/,
  annual: /\b\d{1,10}\b/,
};
