import { IFormControl } from "../../common/interfaces/inputs";

export const forgotPasswordFormControls: { email: IFormControl } = {
  email: {
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail",
        max: 100,
      },
      {
        required: true,
        message: "Please input your E-mail",
      },
    ],
    name: "email",
    placeholder: "Email",
    maxLength: 100,
  },
};
