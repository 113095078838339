import { createSlice } from "@reduxjs/toolkit";
import { IProfileState, IUserModel } from "../interfaces/profile";
import { profileApi } from "../../../api/profile.api";
import { openModal } from "../../common/slices/modal.slice";
import { uploadProfileImage } from "../../auth/slices/auth.slice";
import { IDemoSubscriptionParams } from "../interfaces/subscription";

const initialState: IProfileState = {};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updatePassLoading(state, action) {
      state.updatePassLoading = action.payload;
    },
    updatePass(state) {
      state.updatePassLoading = false;
    },
    updateProfileLoading(state, action) {
      state.updateProfileLoading = action.payload;
    },
    editProfile(state) {
      state.updatePassLoading = false;
    },
    uploadAvatarLoading(state, action) {
      state.uploadAvatarLoading = action.payload;
    },
    uploadImage(state) {
      state.uploadAvatarLoading = false;
    },
    applyingDemoSubscriptionLoading(state, action) {
      state.applyingDemoSubscriptionStatus = action.payload;
    },
    applyDemoSubscriptions(state, action) {
      state.applyDemoSubscription = action.payload;
      state.applyingDemoSubscriptionStatus = false;
    },
  },
});

export const uploadAvatar = (data: File) => async (dispatch: any) => {
  if (data.size < 5000000) {
    dispatch(uploadAvatarLoading(true));
    const formData: FormData = new FormData();
    formData.append("multipartFile", data);
    try {
      const res = await profileApi.uploadAvatar(formData);
      if (res.errorMessage) {
        dispatch(
          openModal({
            modalTitle: "Sorry",
            modalContent: res.errorMessage,
            buttonLabel: "Close",
            closable: false,
            buttonVisible: true,
          }),
        );
        dispatch(uploadAvatarLoading(false));
      } else {
        dispatch(uploadProfileImage(res));
        dispatch(uploadImage());
      }
    } catch (e) {
      dispatch(uploadAvatarLoading(false));
    }
  } else {
    dispatch(
      openModal({
        modalTitle: "Sorry",
        modalContent: "File size too large",
        buttonLabel: "Close",
        closable: false,
        buttonVisible: true,
      }),
    );
  }
};

export const updatePassword = (pass: string) => async (dispatch: any) => {
  dispatch(updatePassLoading(true));
  try {
    await profileApi.updatePassword(pass);
    dispatch(updatePass());
    dispatch(
      openModal({
        modalTitle: "Success",
        modalContent: "Password was successfully updated",
        buttonLabel: "Close",
        closable: false,
        buttonVisible: true,
      }),
    );
  } catch (e) {
    dispatch(updatePassLoading(false));
  }
};

export const updateProfile =
  (data: IUserModel, success: () => void) => async (dispatch: any) => {
    dispatch(updateProfileLoading(true));
    try {
      await profileApi.updateProfile(data);
      dispatch(editProfile());
      success();
    } catch (e) {
      dispatch(updateProfileLoading(false));
    }
  };

export const applyDemoSubscription =
  (demo: boolean, id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(applyingDemoSubscriptionLoading(true));
    try {
      const params: IDemoSubscriptionParams = {
        user_id: id,
        apply_demo: demo,
      };
      const res = await profileApi.applyDemoSubscription(params);
      dispatch(applyDemoSubscriptions(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(applyingDemoSubscriptionLoading(false));
    }
  };

const { actions, reducer } = profileSlice;

export const {
  updatePassLoading,
  updatePass,
  updateProfileLoading,
  editProfile,
  uploadImage,
  uploadAvatarLoading,
  applyingDemoSubscriptionLoading,
  applyDemoSubscriptions,
} = actions;

export default reducer;
