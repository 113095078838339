import { openModal } from "../../common/slices/modal.slice";
import { createSlice } from "@reduxjs/toolkit";
import { IAddRecordRequest, IRecordState } from "../interfaces/record";
import { recordApi } from "../../../api/record.api";
import { IDeleteIds, IFetchList } from "../interfaces/dashboardCommon";
import { DownloadHelper } from "../utils/download.helper";

const initialState: IRecordState = {};

export const recordSlice = createSlice({
  name: "record",
  initialState,
  reducers: {
    fetchRecordLoading(state, action) {
      state.fetchRecordLoading = action.payload;
    },
    fetchAllRecordLoading(state, action) {
      state.fetchAllRecordLoading = action.payload;
    },
    getPdfLoading(state, action) {
      state.getPdfLoading = action.payload;
    },
    downloadPdfLoading(state, action) {
      state.downloadPdfLoading = action.payload;
    },
    fetchSingleRecordLoading(state, action) {
      state.fetchSingleRecordLoading = action.payload;
    },
    deleteRecordLoading(state, action) {
      state.deleteRecordLoading = action.payload;
    },
    addRecordLoading(state, action) {
      state.addRecordLoading = action.payload;
    },
    updateRecordLoading(state, action) {
      state.updateRecordLoading = action.payload;
    },
    searchLoading(state, action) {
      state.searchLoading = action.payload;
    },
    addRecord(state, action) {
      state.recordList?.push({
        ...action.payload.recordItem,
        key: action.payload.recordItem.id,
      });
      state.addRecordLoading = false;
    },
    updateRecord(state, action) {
      return {
        ...state,
        record: { ...state.record, ...action.payload.recordItem },
        updateRecordLoading: false,
      };
    },
    getRecordList(state, action) {
      state.recordList = action.payload.list.map((item: { id: number }) => ({
        ...item,
        key: item.id,
      }));
      state.totalRecords = action.payload.totalElements;
      state.fetchRecordLoading = false;
    },
    getAllRecordList(state, action) {
      state.recordAllList = action.payload.list.map((item: { id: number }) => ({
        ...item,
        key: item.id,
      }));
      state.fetchAllRecordLoading = false;
    },
    getSearchResults(state, action) {
      state.searchResults = action.payload.list.map((item: { id: number }) => ({
        ...item,
        key: item.id,
      }));
      state.searchLoading = false;
    },
    getSingleRecord(state, action) {
      state.record = action.payload.recordFullItem;
      state.fetchSingleRecordLoading = false;
    },
    deleteRecord(state, action) {
      return {
        ...state,
        recordList: state.recordList?.filter(
          (item: any) => action.payload.ids_list.indexOf(item.id) === -1,
        ),
        deleteRecordLoading: false,
      };
    },
    deleteAllRecords(state, action) {
      return {
        ...state,
        recordList: [],
        deleteRecordLoading: false,
      };
    },
    getRecordPdf(state, action) {
      state.recordReport = action.payload;
      state.getPdfLoading = false;
    },
    getFilenameRecordPdf(state, action) {
      state.filenameRecord = action.payload;
      state.getPdfLoading = false;
    },
    downloadRecordPdf(state) {
      state.downloadPdfLoading = false;
    },
    checkNaics(state, action) {
      state.checkNaicsCode = action.payload.naicsCodeItem;
      state.checkNaicsCodeLoading = false;
    },
    checkNaicsCodeLoading(state, action) {
      state.checkNaicsCodeLoading = action.payload;
    },
  },
});

export const fetchRecordPdf = (id: number) => async (dispatch: any) => {
  dispatch(getPdfLoading(true));
  try {
    const res = await recordApi.getRecordPdf(id);
    const url = DownloadHelper.getFileUrl(res.data);
    dispatch(getRecordPdf(url));
    dispatch(getFilenameRecordPdf(res.filename));
  } catch (e) {
    dispatch(getPdfLoading(false));
  }
};

export const checkNaicsCodeRecord =
  (id: number, success?: () => void) => async (dispatch: any) => {
    dispatch(checkNaicsCodeLoading(true));
    try {
      const res = await recordApi.checkNaicsCode(id);
      dispatch(checkNaics(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(checkNaicsCodeLoading(false));
    }
  };

export const downloadRecPdf = (id: number) => async (dispatch: any) => {
  dispatch(downloadPdfLoading(true));
  try {
    const res = await recordApi.downloadRecordPdf(id);
    DownloadHelper.downloadFile(res.data, res.filename);
    dispatch(downloadRecordPdf());
  } catch (e) {
    dispatch(downloadPdfLoading(false));
  }
};

export const fetchRecords = (data: IFetchList) => async (dispatch: any) => {
  dispatch(fetchRecordLoading(true));
  try {
    const res = await recordApi.getRecordList(data);
    dispatch(getRecordList(res));
  } catch (e) {
    dispatch(fetchRecordLoading(false));
  }
};

export const fetchAllRecords = (data: IFetchList) => async (dispatch: any) => {
  dispatch(fetchAllRecordLoading(true));
  try {
    const res = await recordApi.getRecordList(data);
    dispatch(getAllRecordList(res));
  } catch (e) {
    dispatch(fetchAllRecordLoading(false));
  }
};

export const searchRecords = (data: IFetchList) => async (dispatch: any) => {
  dispatch(searchLoading(true));
  try {
    const res = await recordApi.getRecordList(data);
    dispatch(getSearchResults(res));
  } catch (e) {
    dispatch(searchLoading(false));
  }
};

export const fetchSingleRecord = (id: number) => async (dispatch: any) => {
  dispatch(fetchSingleRecordLoading(true));
  try {
    const res = await recordApi.getSingleRecord(id);
    dispatch(getSingleRecord(res));
  } catch (e) {
    dispatch(fetchSingleRecordLoading(false));
  }
};

export const createRecord =
  (data: IAddRecordRequest, success: () => void) => async (dispatch: any) => {
    dispatch(addRecordLoading(true));
    try {
      const res = await recordApi.addRecord(data);
      dispatch(addRecord(res));
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent:
            "LARI has finished calculating your most recent upload of 1 record.",
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
      success();
    } catch (e) {
      dispatch(addRecordLoading(false));
    }
  };

export const editRecord =
  (data: IAddRecordRequest, id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(updateRecordLoading(true));
    try {
      const res = await recordApi.updateRecord(data, id);
      dispatch(updateRecord(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(updateRecordLoading(false));
    }
  };

export const destroyRecord =
  (data: IDeleteIds, success?: () => void) => async (dispatch: any) => {
    dispatch(deleteRecordLoading(true));
    try {
      const res = await recordApi.deleteRecord(data);
      dispatch(deleteRecord(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(deleteRecordLoading(false));
    }
  };

export const destroyAllRecords =
  (success?: () => void) => async (dispatch: any) => {
    dispatch(deleteRecordLoading(true));
    try {
      const res = await recordApi.deleteRecordAll();
      dispatch(deleteAllRecords(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(deleteRecordLoading(false));
    }
  };

const { actions, reducer } = recordSlice;

export const {
  fetchSingleRecordLoading,
  fetchRecordLoading,
  deleteRecordLoading,
  addRecordLoading,
  getRecordList,
  getSingleRecord,
  addRecord,
  deleteRecord,
  updateRecord,
  updateRecordLoading,
  searchLoading,
  getSearchResults,
  getRecordPdf,
  getFilenameRecordPdf,
  getPdfLoading,
  downloadRecordPdf,
  downloadPdfLoading,
  fetchAllRecordLoading,
  getAllRecordList,
  checkNaics,
  deleteAllRecords,
  checkNaicsCodeLoading,
} = actions;

export default reducer;
