import { ApiClient } from "./apiClient";
import { ICustomExpenseRequest } from "../modules/dashboard/interfaces/customExpense";

export const customExpenseApi = {
  addCustomExpense: (data: ICustomExpenseRequest) =>
    ApiClient.post({ path: "customExpense/", body: data }),

  updateCustomExpense: (data: ICustomExpenseRequest) =>
    ApiClient.put({ path: "customExpense/update/", body: data }),
};
