import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useAppDispatch } from "../../../store/hooks";
import { closeModal } from "../../common/slices/modal.slice";
import "./ContactModal.override.css";
import "../../home/components/Home.override.css";
import { AppInput } from "../../common/components/input/AppInput";
import { AppButton } from "../../common/components/button/AppButton";
import { contactOneFormControls } from "../../dashboard/forms/contactOne";
import { sendContactMeEmail } from "../../dashboard/slices/email.slice";
import { DirectIds } from "../../../type/DirectIds";

export const ContactModal = (props: {
  name: string;
  title: string;
  img: string;
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [name, setName] = useState(DirectIds.DI6_NONE);

  useEffect(() => {
    if (props.name === "Matthew Godfrey") {
      setName(DirectIds.DI3_MATTHEW);
    }
    if (props.name === "Connor Dempsey") {
      setName(DirectIds.DI2_CONNOR);
    }
    if (props.name === "John Dempsey") {
      setName(DirectIds.DI1_JOHN);
    }
  }, [dispatch, props.name]);

  const handleSubmit = (values: any) => {
    // console.log("Success:", values);
    dispatch(
      sendContactMeEmail({
        comment: values.comment,
        email: values.email,
        name: values.name,
        contactWith: name,
      }),
    );
    form.resetFields();
  };

  return (
    <div className="contactModal">
      <div className="contactModal-header">
        <img
          className="contactModal-header-img"
          src={props.img}
          alt={props.name}
        />
        <div className="contactModal-header-info">
          <p className="contactModal-header-name">{props.name}</p>
          <p className="contactModal-header-title">{props.title}</p>
        </div>
      </div>
      <div className="contactModal-block">
        <h3 className="contactModal-block-title">Send me a private message</h3>
        <Form
          name="contact-us"
          onFinish={handleSubmit}
          className="contactForm contactFormHome"
          form={form}
        >
          <AppInput config={contactOneFormControls.email} />
          <AppInput config={contactOneFormControls.name} />
          <AppInput
            config={contactOneFormControls.comment}
            type="textarea"
            className="contactModal-textarea"
          />
          <div className="contactModal-btnBlock">
            <AppButton
              text="Cancel"
              type="default"
              onClick={() => {
                dispatch(closeModal());
              }}
              className="contactModal-btnWhite infoModalDecline"
            />
            <AppButton
              text="Send"
              className="contactModal-btnOrange contactBtnHome"
              htmlType="submit"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
