import { AppInput } from "../../../../common/components/input/AppInput";
import { signUpFormControls } from "../../../forms/signUp";
import React from "react";
import { AppCheckbox } from "../../../../common/components/checkbox/AppCheckbox";

export const SignUpStep3 = (props: {
  toggleTerms: (event: any) => void;
  checked: boolean;
}) => {
  return (
    <>
      <AppInput config={signUpFormControls.email} />

      <AppInput config={signUpFormControls.password} type="password" />

      <AppInput config={signUpFormControls.confirmPassword} type="password" />
      <AppCheckbox
        config={signUpFormControls.acceptTermsOfConditions}
        className="inputCheckbox"
        checked={props.checked}
        disabled={!props.checked}
      >
        <div className="termsShow" onClick={props.toggleTerms}>
          Terms and Conditions, Terms of use and Terms of services
        </div>
      </AppCheckbox>
    </>
  );
};
