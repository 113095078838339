import { createSlice } from "@reduxjs/toolkit";
import { IAddGroup, IGroupState } from "../interfaces/group";
import { groupApi } from "../../../api/group.api";
import { openModal } from "../../common/slices/modal.slice";
import { IAddIds, IDeleteIds, IFetchList } from "../interfaces/dashboardCommon";
import { DownloadHelper } from "../utils/download.helper";
import { isWrongSubscription } from "../../../type/subscription";

const initialState: IGroupState = {};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    fetchLoading(state, action) {
      state.fetchGroupLoading = action.payload;
    },
    fetchSingleLoading(state, action) {
      state.fetchSingleGroupLoading = action.payload;
    },
    deleteLoading(state, action) {
      state.deleteGroupLoading = action.payload;
    },
    fetchRecordsInGroupLoading(state, action) {
      state.fetchRecordsInGroupLoading = action.payload;
    },
    fetchAllRecordsInGroupLoading(state, action) {
      state.fetchAllRecordsInGroupLoading = action.payload;
    },
    updateGroupLoading(state, action) {
      state.updateGroupLoading = action.payload;
    },
    createGroupLoading(state, action) {
      state.createGroupLoading = action.payload;
    },
    addRecordToGroupLoading(state, action) {
      state.addRecordToGroupLoading = action.payload;
    },
    deleteRecordFromGroupLoading(state, action) {
      state.addRecordToGroupLoading = action.payload;
    },
    getGroupList(state, action) {
      state.groupList = action.payload.list.map((item: { id: number }) => ({
        ...item,
        key: item.id,
        version: 1,
      }));
      state.totalGroups = action.payload.totalElements;
      state.fetchGroupLoading = false;
    },
    getSingleGroup(state, action) {
      state.group = action.payload;
      state.fetchSingleGroupLoading = false;
    },
    getRecordsInGroup(state, action) {
      state.recordsInGroupList = action.payload.list.map(
        (item: { id: number }) => ({ ...item, key: item.id, version: 1 }),
      );
      state.totalRecordsInGroup = action.payload.totalElements;
      state.fetchRecordsInGroupLoading = false;
    },
    getAllRecordsInGroup(state, action) {
      state.recordsAllInGroupList = action.payload.list.map(
        (item: { id: number }) => ({ ...item, key: item.id, version: 1 }),
      );
      state.totalRecordsInGroup = action.payload.totalElements;
      state.fetchAllRecordsInGroupLoading = false;
    },
    deleteGroup(state, action) {
      return {
        ...state,
        groupList: state.groupList?.filter(
          (item: any) => action.payload.ids_list.indexOf(item.id) === -1,
        ),
        deleteGroupLoading: false,
      };
    },
    updateGroup(state, action) {
      return {
        ...state,
        record: { ...state.group, ...action.payload.groupItem },
        updateGroupLoading: false,
      };
    },
    createGroup(state, action) {
      state.groupList?.push({
        ...action.payload.groupItem,
        key: action.payload.groupItem.id,
        version: 1,
      });
      state.createGroupLoading = false;
    },
    addRecordsToGroup(state) {
      state.addRecordToGroupLoading = false;
    },
    deleteRecordsFromGroup(state, action) {
      return {
        ...state,
        groupList: state.groupList?.filter(
          (item: any) => action.payload.ids_list.indexOf(item.id) === -1,
        ),
        deleteRecordFromGroupLoading: false,
      };
    },
    getGroupPdfLoading(state, action) {
      state.getGroupPdfLoading = action.payload;
    },
    downloadGroupPdfLoading(state, action) {
      state.downloadGroupPdfLoading = action.payload;
    },
    getGroupPdf(state, action) {
      state.groupReport = action.payload;
      state.getGroupPdfLoading = false;
    },
    getFilenameGroupPdf(state, action) {
      state.filenameGroup = action.payload;
      state.getGroupPdfLoading = false;
    },
    downloadGroupPdf(state) {
      state.downloadGroupPdfLoading = false;
    },
  },
});

export const fetchGroupPdf = (id: number) => async (dispatch: any) => {
  dispatch(getGroupPdfLoading(true));
  try {
    const res = await groupApi.getGroupPdf(id);
    const url = DownloadHelper.getFileUrl(res.data);
    dispatch(getGroupPdf(url));
    dispatch(getFilenameGroupPdf(res.filename));
  } catch (e) {
    dispatch(getGroupPdfLoading(false));
  }
};

export const loadGroupPdf = (id: number) => async (dispatch: any) => {
  dispatch(downloadGroupPdfLoading(true));
  try {
    const res = await groupApi.downloadGroupPdf(id);
    DownloadHelper.downloadFile(res.data, res.filename);
    dispatch(downloadGroupPdf());
  } catch (e) {
    dispatch(downloadGroupPdfLoading(false));
  }
};

export const addGroup = (data: IAddGroup) => async (dispatch: any) => {
  dispatch(createGroupLoading(true));
  try {
    const res = await groupApi.addGroup(data);
    dispatch(createGroup(res));
  } catch (e) {
    dispatch(createGroupLoading(false));
  }
};

export const addRecordToNewGroup =
  (groupData: IAddGroup, records: IAddIds, success: () => void) =>
  async (dispatch: any) => {
    dispatch(createGroupLoading(true));
    try {
      const res: any = await groupApi.addGroup(groupData);
      await dispatch(createGroup(res));
      dispatch(addRecordToGroupLoading(true));
      await groupApi.addRecordsToGroup(records, res.groupItem.id);
      dispatch(addRecordsToGroup());
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent: `You have successfully added ${records.idsList?.length} records to the group titled: ${res.groupItem.name}`,
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
      success();
    } catch (e) {
      dispatch(createGroupLoading(false));
      dispatch(addRecordToGroupLoading(false));
    }
  };

export const addRecordToGroup =
  (data: IAddIds, id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(addRecordToGroupLoading(true));
    try {
      const res = await groupApi.addRecordsToGroup(data, id);
      dispatch(addRecordsToGroup());
      if (isWrongSubscription(res)) {
        dispatch(
          openModal({
            modalTitle: "Sorry",
            modalContent: res.errorMessage,
            buttonLabel: "Close",
            closable: false,
            buttonVisible: true,
          }),
        );
      } else if (success) {
        success();
      }
    } catch (e) {
      dispatch(addRecordToGroupLoading(false));
    }
  };

export const deleteRecordFromGroup =
  (data: IDeleteIds, id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(deleteRecordFromGroupLoading(true));
    try {
      await groupApi.deleteRecordsFromGroup(data, id);
      dispatch(deleteRecordsFromGroup(id));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(deleteRecordFromGroupLoading(false));
    }
  };

export const fetchGroups = (data: IFetchList) => async (dispatch: any) => {
  dispatch(fetchLoading(true));
  try {
    const res = await groupApi.getGroupList(data);
    dispatch(getGroupList(res));
  } catch (e) {
    dispatch(fetchLoading(false));
  }
};

export const fetchSingleGroup = (id: number) => async (dispatch: any) => {
  dispatch(fetchSingleLoading(true));
  try {
    const res = await groupApi.getSingleGroup(id);
    dispatch(getSingleGroup(res));
    return res.recordList;
  } catch (e) {
    dispatch(fetchSingleLoading(false));
    return [];
  }
};

export const fetchRecordsInGroup =
  (id: number, data: IFetchList) => async (dispatch: any) => {
    dispatch(fetchRecordsInGroupLoading(true));
    try {
      const res = await groupApi.getRecordsInGroup(id, data);
      dispatch(getRecordsInGroup(res));
      return res.list.map((item: any) => item.id);
    } catch (e) {
      dispatch(fetchRecordsInGroupLoading(false));
      return [];
    }
  };

export const fetchAllRecordsInGroup =
  (id: number, data: IFetchList) => async (dispatch: any) => {
    dispatch(fetchAllRecordsInGroupLoading(true));
    try {
      const res = await groupApi.getRecordsInGroup(id, data);
      dispatch(getAllRecordsInGroup(res));
      return res.list.map((item: any) => item.id);
    } catch (e) {
      dispatch(fetchAllRecordsInGroupLoading(false));
      return [];
    }
  };

export const editGroup =
  (data: IAddGroup, id: number) => async (dispatch: any) => {
    dispatch(updateGroupLoading(true));
    try {
      const res = await groupApi.updateGroup(data, id);
      dispatch(updateGroup(res));
    } catch (e) {
      dispatch(updateGroupLoading(false));
    }
  };

export const destroyGroup =
  (data: IDeleteIds, success?: () => void) => async (dispatch: any) => {
    dispatch(deleteLoading(true));
    try {
      const res = await groupApi.deleteGroup(data);
      dispatch(deleteGroup(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(deleteLoading(false));
    }
  };

const { actions, reducer } = groupSlice;

export const {
  deleteLoading,
  fetchLoading,
  fetchSingleLoading,
  getGroupList,
  getSingleGroup,
  deleteGroup,
  fetchRecordsInGroupLoading,
  getRecordsInGroup,
  getAllRecordsInGroup,
  updateGroupLoading,
  updateGroup,
  addRecordToGroupLoading,
  addRecordsToGroup,
  createGroup,
  createGroupLoading,
  downloadGroupPdfLoading,
  downloadGroupPdf,
  getGroupPdf,
  getGroupPdfLoading,
  deleteRecordsFromGroup,
  deleteRecordFromGroupLoading,
  fetchAllRecordsInGroupLoading,
  getFilenameGroupPdf,
} = actions;

export default reducer;
