import { createSlice } from "@reduxjs/toolkit";
import { zbivApi } from "../../../api/zbiv.api";
import { IZbiv, IZbivState } from "../interfaces/zbiv";
import { DownloadHelper } from "../utils/download.helper";
import { openErrorMessage } from "../../common/components/error/ErrorMessageModal";
import { isWrongSubscription } from "../../../type/subscription";

const initialState: IZbivState = {};

export const zbivSlice = createSlice({
  name: "zbiv",
  initialState,
  reducers: {
    fetchZbivLoading(state, action) {
      state.fetchZbivLoading = action.payload;
    },
    downloadPdfLoading(state, action) {
      state.downloadPdfLoading = action.payload;
    },
    getZbivList(state, action) {
      state.zbivList = action.payload[0];
      state.fetchZbivLoading = false;
    },
    getZbivListCommon(state, action) {
      state.zbivListCommon = action.payload;
      state.fetchZbivLoading = false;
    },
    downloadZbivPdf(state) {
      state.downloadPdfLoading = false;
    },
  },
});

export const fetchZbivs =
  (data: IZbiv, success?: () => void) => async (dispatch: any) => {
    dispatch(fetchZbivLoading(true));
    try {
      const res = await zbivApi.getZbivList(data);
      if (isWrongSubscription(res)) {
        dispatch(openErrorMessage(res.errorMessage));
      } else {
        if (success) {
          success();
        }
      }
      dispatch(getZbivList(res));
    } catch (e) {
      dispatch(fetchZbivLoading(false));
    }
  };

export const fetchZbivsCommonForNaics =
  (data: IZbiv) => async (dispatch: any) => {
    dispatch(fetchZbivLoading(true));
    try {
      const res = await zbivApi.getZbivList(data);
      if (isWrongSubscription(res)) {
        dispatch(openErrorMessage(res.errorMessage));
      }
      dispatch(getZbivListCommon(res));
    } catch (e) {
      dispatch(fetchZbivLoading(false));
    }
  };

export const downloadZbivPdfReport = (data: IZbiv) => async (dispatch: any) => {
  dispatch(downloadPdfLoading(true));
  try {
    const res: Blob = await zbivApi.downloadZbivPdf(data);
    DownloadHelper.downloadFile(res, "Zbiv Pdf.pdf");
    dispatch(downloadZbivPdf());
  } catch (e) {
    dispatch(downloadPdfLoading(false));
  }
};

const { actions, reducer } = zbivSlice;

export const {
  fetchZbivLoading,
  getZbivList,
  downloadPdfLoading,
  downloadZbivPdf,
  getZbivListCommon,
} = actions;

export default reducer;
