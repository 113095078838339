import "./Terms.override.css";

export const TextOfTerms = () => {
  return (
    <>
      <p
        style={{
          marginTop: 0,
          marginBottom: 0,
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <strong>
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontVariant: "small-caps",
            }}
          >
            Terms of Service
          </span>
        </strong>
      </p>
      <p
        style={{
          marginTop: 0,
          marginBottom: 0,
          textAlign: "center",
          lineHeight: "normal",
          fontSize: "8pt",
        }}
      >
        <strong>
          <span style={{ fontFamily: "Times New Roman Bold" }}>
            Last Modified: May 4, 2023
          </span>
        </strong>
      </p>
      <p
        style={{
          marginTop: 0,
          marginBottom: 0,
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span style={{ fontFamily: "Times New Roman Bold" }}>&nbsp;</span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, lineHeight: "normal" }}>
        <span
          style={{
            fontFamily: "Times New Roman Bold",
            fontVariant: "small-caps",
          }}
        >
          Welcome to LARI
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, lineHeight: "normal" }}>
        <span
          style={{
            fontFamily: "Times New Roman Bold",
            fontVariant: "small-caps",
          }}
        >
          &nbsp;
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 8, textAlign: "justify" }}>
        Welcome to heylari.com, owned and operated by LARI LLC and any of its
        subsidiaries or affiliates (&ldquo;<strong>Company</strong>,&rdquo;
        &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>&rdquo; or
        &ldquo;<strong>our</strong>&rdquo;). These Terms of Service (&ldquo;
        <strong>Terms of Service</strong>&rdquo;) govern your use of the website
        located at www.heylari.com (the &ldquo;<strong>Site</strong>&rdquo;) and
        all related products, services, tools, mobile applications, web
        applications, and any other technology platforms or tools located at any
        Company websites, including without limitation, successor website(s) or
        application(s) thereto (collectively, the &ldquo;
        <strong>Services</strong>&rdquo;). The terms &ldquo;
        <strong>you,</strong>&rdquo; &ldquo;<strong>your</strong>&rdquo; or
        &ldquo;<strong>user</strong>&rdquo; refers to you, the user. If you are
        using the Services on behalf of a business, association, or other
        entity, &ldquo;you&rdquo; or &ldquo;your&rdquo; will also refer to such
        business, association, or other entity, unless the context clearly
        dictates otherwise. You agree that you are authorized to consent to
        these terms on behalf of such business, association, or other entity,
        and we can rely on this and that your business will be bound by these
        Terms.
      </p>
      <p style={{ marginTop: 0, marginBottom: 8 }}>
        <strong>
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontVariant: "small-caps",
            }}
          >
            Important Notices
          </span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 8, textAlign: "justify" }}>
        PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
        AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR
        LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
        REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
        AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL
        ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST COMPANY ON AN INDIVIDUAL
        BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
        ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
        (INCLUDING MONETARY, INJUNCTIVE AND DECLARATORY RELIEF) ON AN INDIVIDUAL
        BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST
        US RESOLVED BY A JURY OR IN A COURT OF LAW
        <strong>
          <em>.</em>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 8 }}>
        <strong>
          <em>These Terms Set Forth a Legally Binding Agreement</em>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 8, textAlign: "justify" }}>
        Please read these Terms of Service very carefully before accessing or
        using our Services. By clicking on &quot;accept&quot; or
        &quot;agree&quot; to these Terms of Service when prompted or
        using/continuing to use our Services, you acknowledge you have read and
        understand and agree to be bound by these&nbsp; Terms of Service,
        including those additional terms and conditions and policies referenced
        herein and/or available by hyperlink. Please print a copy of this
        agreement for your records. If you do not agree to all the terms and
        conditions of these Terms of Service, then you may not access or use any
        Services. If these Terms of Service are considered an offer, acceptance
        is expressly limited to these Terms of Service.
      </p>
      <p style={{ marginTop: 0, marginBottom: 8 }}>
        <strong>
          <em>Eligibility to Use the Services</em>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 8, textAlign: "justify" }}>
        To use the Services you must be, and represent and warrant that you are,
        at least the age of majority in your state, province or jurisdiction of
        residence and that you have the authority to represent your business
        entity. You warrant and represent that you (1) have all necessary
        rights, power, and authority to agree to these Terms of Service and
        perform your obligations hereunder, and (2) nothing contained in these
        Terms of Service or in the performance of such obligations will place
        you in breach of any other contract or obligation.
      </p>
      <p style={{ marginTop: 0, marginBottom: 8 }}>
        <strong>
          <em>Privacy Policy</em>
        </strong>
      </p>
      <p
        style={{
          marginTop: 0,
          marginBottom: 0,
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        Please refer to our Privacy Policy for information about how we collect,
        use, store and disclose your personal information.
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, lineHeight: "normal" }}>
        &nbsp;
      </p>
      <ol type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontVariant: "small-caps",
          }}
        >
          The Services
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        The Services provide you with access to information that can be used to
        estimate potential business interruption exposure and related insights.
        More specifically, the Services utilize a mixture of public and
        proprietary data collected by Company, and company details and financial
        information uploaded to the Services by the user, to estimate potential
        business interruption exposure values. The user also has access to
        general commentary and data about business interruption exposure and
        loss scenarios which are not tailored or specific to any particular user
        or business. The user may also utilize proprietary tools to analyze
        estimated exposures. All Services are subject to change at any time
        without notice, at the sole discretion of the Company. We reserve the
        right to discontinue any Service at any time. Any offer for any Service
        is void where prohibited.
      </p>
      <ol start={2} type="1" style={{ margin: "0pt", padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontStyle: "italic",
            fontVariant: "small-caps",
          }}
        >
          The Services Do Not Provide Professional Advice or Services
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        Company provides the Services and the information, materials, and/or
        data (collectively, &ldquo;<strong>Information</strong>&rdquo;)
        contained therein for informational and educational purposes only. We do
        not warrant or represent the accuracy, completeness, currency, or
        suitability of any Information available made through the Services.
        Company does not provide any professional advice via the Services, and
        the Information should not be so construed or used. Company is not and
        shall in no event be responsible for, or in any way control, the actual
        amounts of insurance paid to you.{" "}
        <span style={{ textTransform: "uppercase" }}>
          Company is not responsible or liable for any difference between the
          Amount of Company&rsquo;s estimates provided via the Services and ANY
          OTHER ANALYSIS OR METRIC, WHETHER CALCULATED BY COMPANY OR A THIRD
          PARTY.
        </span>{" "}
        <span style={{ textTransform: "uppercase" }}>
          Using, accessing, and/or browsing the SERVICES and/or providing
          personal INFORMATION to Company does not create a professional-client,
          or similar relationship between you and Company. Nothing contained in
          the SERVICES is intended to create a professional-client, or similar
          relationship to replace the services of a licensed, trained
          professional, or to be a substitute for advice of A LICENSED, trained
          professional. You should not rely on anything contained in the
          SERVICES.
        </span>{" "}
        THE SERVICE IS PROVIDED ON AN AS-IS BASIS.&nbsp; You hereby agree that
        you shall not make any decision based in whole or in part on anything
        contained in the Services. You are solely responsible for the use of any
        Information available through the Services.{" "}
        <strong>
          NEVER DISREGARD PROFESSIONAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF
          SOMETHING YOU HAVE READ IN CONNECTION WITH THE SERVICES.
        </strong>
      </p>
      <ol start={3} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontStyle: "italic",
            fontVariant: "small-caps",
          }}
        >
          Intellectual Property Ownership; Third-Party Websites
          <ol
            type="A"
            style={{
              margin: "0pt",
              marginRight: "0pt",
              marginLeft: "0pt",
              padding: "0pt",
            }}
          >
            <li style={{ marginLeft: "34pt", padding: "2pt" }}>
              <span style={{ fontVariant: "normal" }}>
                Intellectual Property Ownership
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontVariant: "normal",
                }}
              >
                . All content on the Services (including, for example, text,
                designs, graphics, logos, icons, images, audio clips, downloads,
                interfaces, information, code and software, and the selection
                and manner of compilation and presentation) (collectively, the
                &ldquo;
              </span>
              <span style={{ fontStyle: "normal", fontVariant: "normal" }}>
                Content
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontVariant: "normal",
                }}
              >
                &rdquo;), is owned by Company, our content providers, other
                users of the Services, or our licensors (as applicable), and may
                be protected by copyright, trademark, and other applicable laws.
              </span>
              <br />
              <br />
              <span
                style={{
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontVariant: "normal",
                }}
              >
                Your access to and use of the Services does not grant you any
                license or right to use any copyrighted materials or any
                trademark, logo, or service mark displayed on the Website or in
                the Content. Company, our content providers, other users, or our
                licensors (as applicable) retain full and complete title to and
                reserve all rights in the material on the Services, including
                all associated intellectual property rights. Company neither
                warrants nor represents that your use of materials on the
                Services will not infringe rights of third parties.
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <br />
        You may access the Services only for your permitted use under these
        Terms of Service, and you may not modify or delete any copyright,
        trademark, or other proprietary notice relating to any material you
        access. You agree not to display or use in any manner the Company marks
        without Company&apos;s advance written permission.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <br />
        All software used on the Services is the property of Company or our
        licensors and protected by United States and international copyright
        laws, and subject to separate license terms, in which case those license
        terms will govern such software. You agree not to reproduce, duplicate,
        copy, sell, resell, or exploit any portion of the Services or the
        Website, use of the Services or Website, or access to the Services or
        any contact on the Website through which the Services are provided,
        without express written permission by us.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <strong>
          <em>
            <span style={{ fontVariant: "small-caps" }}>&nbsp;</span>
          </em>
        </strong>
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        All rights not expressly granted herein are reserved by Company, our
        affiliates, and licensors. You agree to abide by all additional
        restrictions displayed on the Services as they may be updated from time
        to time.
      </p>
      <ol start={2} type="A" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Feedback
          <span style={{ fontStyle: "normal" }}>.</span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;By sending us any feedback, comments, questions, ideas,
            proposals, or suggestions concerning Company or any of our Services
            whether online, by email, by postal mail, or otherwise
            (collectively, &ldquo;
          </span>
          <span style={{ fontStyle: "normal" }}>Feedback</span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &rdquo;), you represent and warrant (i) that you have the right to
            disclose the Feedback, (ii) that the Feedback does not violate the
            rights of any other person or entity, including, but not limited to,
            intellectual property rights, such as infringing a copyright,
            trademark, or patent; violating a right of privacy, attribution, or
            withdrawal; or otherwise misappropriating a trade secret, and (iii)
            that your Feedback does not contain the confidential or proprietary
            information of any third party or parties. By sending us any
            Feedback, you further (1) agree that we are under no obligation of
            confidentiality, express or implied, with respect to the Feedback,
            (2) acknowledge that we may have something similar to the Feedback
            already under consideration or in development, and (3) grant us an
            irrevocable, non-exclusive, royalty-free, perpetual, worldwide
            license, under all intellectual property rights, to use, make, have
            made, incorporate into our Services, modify, copy, display, perform,
            distribute, prepare derivative works, publish, distribute, and
            sublicense the Feedback, without any credit or compensation to you.
            This Feedback section shall survive any termination of your use of
            any aspect of the Services.
          </span>
        </li>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Third-Party Websites.
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            Certain content, products, and services available via the Services
            may include materials from third parties or provide you with access
            to third-party tools, products, and resources over which we neither
            monitor nor have any control nor input.
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            Further, third-party links on our Site may direct you to third-party
            websites that are not affiliated with us. We are not responsible for
            examining or evaluating the content or accuracy of any third-party
            materials or websites or for any other materials, products, or
            services of third parties. The views expressed in third-party
            materials, websites, resources, products, or services are those of
            such third-party and do not necessarily reflect our views.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
        }}
      >
        You acknowledge and agree that we provide access to such materials,
        products, websites, tools, and resources &ldquo;as is&rdquo; and
        &ldquo;as available&rdquo; without any warranties, representations, or
        conditions of any kind and without any endorsement. We do not warrant
        and will not have any liability or responsibility arising from or
        relating to third-party materials, websites, tools, products, and
        resources. Any use by you of third-party materials, tools, products,
        services, and resources offered through the Services is entirely at your
        own risk and discretion, and you should ensure that you are familiar
        with and approve of the terms on which such items are provided by the
        relevant third-party provider(s).
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        We are not liable for any harm or damages related to the purchase or use
        of goods, services, resources, content, or any other transactions made
        in connection with any third-party websites. Please review carefully the
        third-party&apos;s policies and practices and make sure you understand
        them before you engage in any transaction. You may not use third-party
        content without that third-party&rsquo;s permission or as otherwise
        allowed by law. Complaints, claims, concerns, or questions regarding
        third-party products or services should be directed to the applicable
        third-party.
      </p>
      <ol start={4} type="A" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "52.67pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "1.33pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Errors, Inaccuracies and Omissions.
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            Occasionally there may be Information on the Services that contains
            typographical errors, inaccuracies, or omissions that may relate to
            the Services.
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            We reserve the right, without prior notice, to (i) correct any
            errors, inaccuracies, or omissions, and (ii) change or update
            Information if any Information in the Services or on any related
            website is inaccurate at any time (including after you have
            submitted your order).
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        We undertake no obligation to update, amend, or clarify Information in
        the Services or on any related website, including without limitation,
        pricing information, except as required by law. No specified update or
        refresh date applied in the Services or on any related website should be
        taken to indicate that all Information in the Services or on any related
        website has been modified or updated.
      </p>
      <ol start={4} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontFamily: "Times New Roman Bold",
            fontWeight: "bold",
            fontStyle: "italic",
            fontVariant: "small-caps",
          }}
        >
          <span style={{ fontStyle: "normal" }}>
            User Account and Security&nbsp;
          </span>
          <ol
            type="A"
            style={{ marginRight: "0pt", marginLeft: "0pt", padding: "0pt" }}
          >
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                paddingLeft: "2pt",
                fontFamily: "Times New Roman",
                fontVariant: "normal",
              }}
            >
              Agreement to Provide Accurate Registration Information.
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;If you wish to participate in certain aspects of the
                Services, you may need to register for an account on the
                Services (&ldquo;
              </span>
              <span style={{ fontStyle: "normal" }}>Account</span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &rdquo;). By creating an Account, you agree to (i) provide
                accurate, current, and complete registration information about
                yourself, (ii) maintain and promptly update as necessary your
                Account information, (iii) maintain the security of your
                password and accept all risks of unauthorized access to your
                Account and the information you provide us, and (iv) immediately
                notify us if you discover or otherwise suspect any security
                breaches related to the Services or your Account.&nbsp;
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
        }}
      >
        By creating an Account, you consent to receive electronic communications
        from Company (e.g., via email). These communications may include notices
        about your Account (e.g., password changes and other transactional
        information) and are part of your relationship with us. You agree that
        any notices, agreements, disclosures, or other communications that we
        send to you electronically will satisfy any legal communication
        requirements, including, but not limited to, that such communications be
        in writing. We may also send you promotional communications via email,
        including, but not limited to, newsletters, special offers, surveys, and
        other news and information we think will be of interest to you. You may
        opt out of receiving these promotional emails at any time by following
        the unsubscribe instructions provided therein.
      </p>
      <ol start={2} type="A" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          User is Responsible for Equipment and Software to Connect to Services.
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;You must provide all equipment and software necessary to
            connect to the Services. You are solely responsible for any fees,
            including Internet connection or mobile fees, that you incur when
            accessing the Services.
          </span>
        </li>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Company May Suspend Hacked Accounts.
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            Company reserves the right to terminate, suspend, or restrict your
            access to any Account(s) if (i) we discover you have created
            multiple Accounts for the same user, or (ii) we suspect that the
            Account(s) have been or will be used for any illegal, fraudulent, or
            otherwise unauthorized purposes. Under no circumstances shall
            Company or other persons be responsible or liable for any direct,
            indirect, consequential, or other losses (including lost revenue,
            lost profits, lost business opportunities, loss of goodwill, or
            reputational harm), damages, or costs suffered by you or any other
            person or entity due to any such termination, suspension, or
            restriction of access to any Account(s).
          </span>
        </li>
        <li
          style={{
            marginLeft: "52.67pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "1.33pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          User May Not Use the Services for Illegal Activity.
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            You agree to use the Services only for purposes that are legal,
            proper, and in accordance with these Terms of Service and any
            applicable laws or regulations. Without limitation, you may not, and
            may not allow any third-party to: (i) undertake any unlawful
            activity which would violate or assist in violation of any law,
            statute, ordinance, or regulation, sanctions program administered in
            any relevant country; (ii) impersonate another person (via the use
            of an email address or otherwise); (iii) upload, post, transmit, or
            otherwise make available through the Services any content that
            infringes the intellectual or proprietary rights of any party; (iv)
            operate to defraud Company, other users, or any other person; (v)
            provide false, inaccurate, or misleading information; (vi) use the
            Services to violate the legal rights (such as rights of privacy and
            publicity) of others; (vii) engage in, promote, or encourage illegal
            activity (including, without limitation, tax evasion or money
            laundering); (viii) harvest or otherwise collect information from
            the Services about others, including without limitation email
            addresses, without proper consent; (ix) exploit the Services for any
            unauthorized commercial purpose; (x) modify, adapt, translate, or
            reverse engineer any portion of the Services; (xi) remove any
            copyright, trademark, or other proprietary rights notices contained
            in or on the Services or any part of it; (xii) use any robot,
            spider, site search/retrieval application, or other device to
            retrieve or index any portion of the Services or the content posted
            on the Services, or to collect information about its users for any
            unauthorized purpose; (xiii) create user accounts by automated
            means, or under false or fraudulent pretenses; or (xiv) access or
            use the Services for the purpose of creating a product or service
            that is competitive with any of our products or Services.&nbsp;
          </span>
        </li>
      </ol>
      <ol start={5} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontStyle: "normal",
              fontVariant: "small-caps",
            }}
          >
            Payment Services; Fees
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;
          </span>
          <ol
            type="A"
            style={{ marginRight: "0pt", marginLeft: "0pt", padding: "0pt" }}
          >
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                paddingLeft: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                We Reserve the Right to Refuse any Order you Place through the
                Services.
              </span>
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontWeight: "normal",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                All products and services are subject to availability by
                Company. In the event Company needs to cancel your order for
                issues of availability, in whole or in part, Company will, at
                its sole discretion, (i) contact you directly via the
                information you provided with your order to discuss potential
                substitutions or (ii) provide a refund to the payment card used
                to make the purchase. Company also reserves the right to not
                process or to cancel your transaction in certain circumstances,
                for example, if your credit card is declined, if we suspect the
                request or transaction is fraudulent, or in other circumstances
                Company deems appropriate in its sole discretion.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                paddingLeft: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Payment Processors
              </span>
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontStyle: "normal",
                  fontVariant: "small-caps",
                }}
              >
                .
              </span>
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontVariant: "small-caps",
                }}
              >
                &nbsp;
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                Company uses third-party providers (i.e., Stripe) to securely
                store your payment card information and process your payments
                (&ldquo;
              </span>
              <span style={{ fontStyle: "normal" }}>Payment Processors</span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &rdquo;). You expressly authorize us or our Payment Processors
                to charge you for each transaction. Our Payment Processors may
                ask you to supply additional information relevant to your
                transaction, including your credit card number, the expiration
                date of your credit card, and your email and postal addresses
                for billing and notification (such information,
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &ldquo;
              </span>
              <span style={{ fontStyle: "normal" }}>Payment Information</span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &rdquo;). You will provide all Payment Information directly to
                our Payment Processors. You represent and warrant that you have
                the legal right to use all payment method(s) represented by any
                such Payment Information. When you initiate a transaction
                through the Services, you authorize our Payment Processors to
                complete your transaction, and to charge your payment method for
                the transaction (plus any applicable taxes and other charges).
                By initiating a transaction, you agree to the pricing, payment,
                and billing policies applicable to such fees and charges, as
                posted or otherwise communicated to you. All fees and applicable
                taxes, if any, are payable in United States dollars. You will
                receive a confirmation email after we confirm the payment for
                your order. Your order is not binding on Company until accepted
                and confirmed by Company. All payments made are non-refundable
                and non-transferable except as expressly provided in this
                Agreement.&nbsp;
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                paddingLeft: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Transaction Cancellation; Chargebacks
              </span>
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontStyle: "normal",
                }}
              >
                .
              </span>
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontWeight: "normal",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                You may only cancel a transaction pursuant to the policies as
                communicated to you by us or by our Payment Processors. We may
                institute a chargeback policy as we deem appropriate in the
                event that you or your bank does not honor a payment obligation
                or if our Payment Processors question our ability to collect
                funds from you. As part of such chargeback policy, we may in our
                sole discretion suspend, terminate, or otherwise limit your
                ability to use the Services or otherwise take any action we or
                our Payment Processors deem necessary.&nbsp;
              </span>
            </li>
            <li
              style={{
                marginLeft: "34.67pt",
                marginBottom: "6pt",
                textAlign: "justify",
                paddingLeft: "1.33pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Fee Disputes.
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                If you have any concerns or objections regarding charges, you
                agree to raise them with us first and you agree not to cancel or
                reject any credit card or third-party payment processing charges
                unless you have made a reasonable attempt at resolving the
                matter directly with Company.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                paddingLeft: "2pt",
              }}
            >
              Verification.
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;Company reserves the right, in its sole discretion, to
                take steps to verify your identity in connection with your
                transaction. You may need to provide additional information to
                verify your identity before completing your transaction (such
                information is included within the definition of Payment
                Information).&nbsp;
              </span>
            </li>
          </ol>
        </li>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontStyle: "normal",
              fontVariant: "small-caps",
            }}
          >
            Consent to Use of Information
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
          backgroundColor: "#ffffff",
        }}
      >
        <span style={{ letterSpacing: "0.15pt" }}>
          When creating an Account and using the Services, you may be asked to
          provide certain personal information. By providing such personal
          information, you agree to the terms of our Privacy Policy and
          expressly consent to Company&rsquo;s collection, storage, use, and
          disclosure of your personal information in accordance with the Privacy
          Policy.
        </span>
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
          backgroundColor: "#ffffff",
        }}
      >
        You represent and warrant, and covenant to Company, that you have all
        necessary rights and permissions to (a) disclose all information
        provided to Company, and (b) authorize
        <span style={{ letterSpacing: "0.15pt" }}>
          &nbsp;Company to process information in accordance with any applicable
          law or regulation relating to the privacy, protection, security,
          collection, storage, use, and disclosure of the information provided
          in accordance with the Privacy Policy.
        </span>
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
          backgroundColor: "#ffffff",
        }}
      >
        <span style={{ letterSpacing: "0.15pt" }}>
          You grant Company and all other persons or entities involved in the
          operation of the Services the right to transmit, monitor, retrieve,
          store, and use your information in connection with the operation of
          the Services. Company cannot and does not assume any responsibility or
          liability for any information you submit, or your or third
          parties&rsquo; use or misuse of information transmitted or received
          using the Services.
        </span>
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span style={{ letterSpacing: "0.15pt" }}>
          We may derive and compile, either manually or automatically,
        </span>
        <span style={{ letterSpacing: "0.15pt" }}>&nbsp;</span>
        <span style={{ letterSpacing: "0.15pt" }}>
          anonymized, and aggregated data related to the performance, operation,
          and use of the Services (&ldquo;
        </span>
        <strong>
          <span style={{ letterSpacing: "0.15pt" }}>
            Statistical Information
          </span>
        </strong>
        <span style={{ letterSpacing: "0.15pt" }}>
          &rdquo;) including by you, and use such Statistical Information for
          our business purposes, including for operations management, for
          research and development, and for sharing with relevant parties. We
          own the rights in and to such Statistical Information.&nbsp;
        </span>
      </p>
      <ol start={7} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontStyle: "normal",
              fontVariant: "small-caps",
            }}
          >
            DMCA
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        Company respects the intellectual property rights of others. It is our
        policy to respond promptly to any claim that Content infringes the
        copyright or other intellectual property rights of any person. Company
        will use reasonable efforts to investigate notices of alleged
        infringement and will take appropriate action the Digital Millennium
        Copyright Act (&ldquo;<strong>DMCA</strong>&rdquo;) and these Terms of
        Service. If you believe that your copyrighted work is infringed by
        Content, please provide a written DMCA notice to Company at:{" "}
        <a href="mailto:info@heylari.com" style={{ textDecoration: "none" }}>
          <u>
            <span style={{ color: "#0563c1" }}>info@heylari.com</span>
          </u>
        </a>
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "0pt",
          lineHeight: "normal",
        }}
      >
        &nbsp;
      </p>
      <ol type="A" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Filing a DMCA &ldquo;Take Down&rdquo; Notification.
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            If you are a copyright owner or an agent thereof and believe that
            any Content infringes upon your copyrights, you may submit a
            take-down notification (&ldquo;
          </span>
          <span style={{ fontStyle: "normal" }}>Take-Down Notification</span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            ) pursuant to the DMCA by providing us with the following
            information in writing (see 17 U.S.C. &sect; 512 for further
            detail):
          </span>
        </li>
      </ol>
      <ol type="i" className="awlist1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "108pt",
            marginBottom: "6pt",
            textIndent: "-36pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              width: "24.67pt",
              font: '7pt "Times New Roman"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;
        </li>
        <li
          style={{
            marginLeft: "108pt",
            textIndent: "-36pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              width: "21.34pt",
              font: '7pt "Times New Roman"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works, a representative list of such works
          at that website;
        </li>
        <li
          style={{
            marginLeft: "108pt",
            textIndent: "-36pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              width: "18.01pt",
              font: '7pt "Times New Roman"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material; **Providing URLs in
          the body of your DMCA notification is the best way to help us locate
          content quickly**
        </li>
        <li
          style={{
            marginLeft: "108pt",
            textIndent: "-36pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              width: "18.67pt",
              font: '7pt "Times New Roman"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Information reasonably sufficient to permit us to contact you (the
          complaining party), such as an address, telephone number, and
          electronic mail address at which you (the complaining party) may be
          contacted;
        </li>
        <li
          style={{
            marginLeft: "108pt",
            textIndent: "-36pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              width: "22.01pt",
              font: '7pt "Times New Roman"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          A statement that you (the complaining party) have a good faith belief
          that use of the material in the manner complained of is not authorized
          by the copyright owner, its agent, or the law;
        </li>
        <li
          style={{
            marginLeft: "108pt",
            textIndent: "-36pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              width: "18.67pt",
              font: '7pt "Times New Roman"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that you (the complaining party) are
          authorized to act on behalf of the owner of an exclusive right that is
          allegedly infringed; and
        </li>
        <li
          style={{
            marginLeft: "108pt",
            marginBottom: "8pt",
            textIndent: "-36pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              width: "15.34pt",
              font: '7pt "Times New Roman"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          **(Optional) Provide information, if possible, sufficient to permit us
          to notify the user(s) who posted the content that allegedly contains
          infringing material.
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "8pt",
          textAlign: "justify",
        }}
      >
        <em>
          Any person who knowingly materially misrepresents that content or an
          activity is infringing or that any material or activity was removed or
          disabled by mistake or misidentification, shall be liable to us and
          possibly others for any damages, including costs and attorneys&rsquo;
          fees incurred by us in removing or disabling access to the material or
          activity claimed to be infringing or in replacing the removed material
          or enabling access to it.
        </em>
      </p>
      <ol start={8} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontWeight: "normal",
              fontStyle: "normal",
              fontVariant: "small-caps",
            }}
          >
            Indemnification
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        You agree to indemnify, defend and hold harmless Company and its
        subsidiaries, affiliates, partners, officers, directors, agents,
        contractors, licensors, service providers, subcontractors, suppliers,
        interns, and employees, harmless from and against any and all losses,
        claims, damages, judgments, demands, actions, proceedings,
        investigations (whether formal or informal), or expenses (including
        reasonable attorneys&rsquo; fees), or threats thereof, due to, arising
        out of or relating to (a) your breach of these Terms of Service or the
        documents they incorporate by reference, (b) your violation of (i) any
        law or (ii) the rights of a third-party, or (c) your use of the
        Services.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        In the event of such a claim, suit, or action, we will attempt to
        provide you notice of the claim, suit, or action at the contact
        information we have for your Account on file (provided, that failure to
        deliver such notice shall not eliminate or reduce your indemnification
        obligations hereunder). Company reserves the right, at its own cost, to
        assume the exclusive defense and control of any matter otherwise subject
        to indemnification by you, in which event you will fully cooperate with
        Company in asserting any available defenses. You agree that the
        provisions in this section will survive any termination of your Account,
        these Terms of Service, or your access to the Services, including the
        purchase or use of any benefits through the Services.
      </p>
      <ol start={9} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "14pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            paddingLeft: "4pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontWeight: "normal",
              fontStyle: "normal",
              fontVariant: "small-caps",
            }}
          >
            Disclaimers and Limitations on Our Liability
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        TO THE EXTENT PERMITTED BY APPLICABLE LAW, COMPANY AND ITS OFFICERS,
        EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES,
        AGENTS, AND LICENSORS DISCLAIM ALL WARRANTIES, CONDITIONS, AND
        REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
        OTHERWISE, INCLUDING, WITHOUT LIMITATION, THOSE RELATED TO
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND
        THOSE ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.
      </p>
      <ol type="A" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Company Makes No Representations or Warranties; Disclaimer.
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;IN NO EVENT WILL COMPANY AND ITS AFFILIATES BE LIABLE TO YOU
            OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN
            RELIANCE UPON SUCH INFORMATION. THE INFORMATION AVAILABLE THROUGH
            THE SERVICES IS PROVIDED FOR GENERAL INFORMATIONAL AND EDUCATIONAL
            PURPOSES ONLY AND DOES NOT CONSTITUTE LEGAL OR OTHER PROFESSIONAL
            ADVICE ON ANY SUBJECT MATTER. COMPANY AND ITS AFFILIATES DO NOT
            ACCEPT ANY RESPONSIBILITY OR LIABILITY FOR ANY LOSS WHICH MAY ARISE
            FROM RELIANCE ON INFORMATION CONTAINED ON THE SERVICES.&nbsp;
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span style={{ textTransform: "uppercase" }}>
          The Services are provided &ldquo;as is&rdquo; and &ldquo;as
          available.&rdquo;&nbsp;
        </span>
        TO THE EXTENT PERMITTED BY APPLICABLE LAW, COMPANY AND ITS AFFILIATES
        MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS
        OF INFORMATION AVAILABLE ON OR THROUGH THE SERVICES, OR THE CONTENT OF
        ANY THIRD-PARTY WEBSITES OR SERVICES LINKED TO OR INTEGRATED WITH OUR
        SERVICES.{" "}
        <span style={{ textTransform: "uppercase" }}>
          We do not represent or warrant that (i) your use of our Services will
          be uninterrupted, timely, secure, or error-free, (ii) any errors in
          the Service will be corrected, (iii) the quality of the Services,
          information or other material purchased or obtained by you will meet
          your expectations, (iv) the Services will be free of any worms or
          viruses or any code of a malicious and/ or destructive nature, (v) the
          results that may be obtained from the use of the Service will be
          accurate or reliable, (vi) your use of the Services will result in any
          specific outcomes, or (vii) the Services are intended to replace
          professional advice. You (and not Company) assume the entire cost of
          all necessary servicing, repair, or correction.
        </span>
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        COMPANY AND ITS AFFILIATES WILL HAVE NO LIABILITY FOR ANY: (i) ERRORS,
        MISTAKES, OR INACCURACIES OF CONTENT; (ii) PERSONAL INJURY OR PROPERTY
        DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES OR
        CONSUMPTION OF ANY CONTENT; (iii) ANY UNAUTHORIZED ACCESS TO OR USE OF
        OUR SERVERS OR OF ANY PERSONAL INFORMATION OR USER DATA; (iv) ANY
        INTERRUPTION OF TRANSMISSION TO OR FROM THE SERVICES; (v) ANY BUGS,
        VIRUSES, TROJAN HORSES OR THE LIKE WHICH MAY BE TRANSMITTED ON OR
        THROUGH THE SERVICES; (vi) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
        RESULT OF THE USE OF ANY CONTENT POSTED OR SHARED THROUGH THE SERVICES;
        OR (vii) LOSS OR DAMAGED CAUSED BY ANOTHER USER&rsquo;S VIOLATION OF
        THESE TERMS OF SERVICE.
      </p>
      <ol start={2} type="A" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            paddingLeft: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Limitation on Liability.
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
            COMPANY AND ITS AFFILIATES, OFFICERS, DIRECTORS, AFFILIATES,
            EMPLOYEES, AGENTS, CONTRACTORS, REPRESENTATIVES, INTERNS, SUPPLIERS,
            SERVICE PROVIDERS, OR LICENSORS BE RESPONSIBLE FOR ANY LOSS
            INCLUDING, WITHOUT LIMITATION, LOST PROFITS, REVENUES, OR FINANCIAL
            LOSSES, OR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR
            PUNITIVE DAMAGES ARISING FROM THESE TERMS OF SERVICE OR THE
            SERVICES, OR FOR ANY DAMAGES RELATED TO THE LOSS OF REVENUE, LOSS OF
            PROFITS, LOSS OF BUSINESS, LOSS OF USE, LOSS OF GOODWILL OR LOSS OF
            DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
            CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF COMPANY HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, IN NO EVENT SHALL THE
        MAXIMUM TOTAL LIABILITY OF COMPANY AND ITS AFFILIATES, FOR ANY CLAIMS
        ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS OF SERVICE OR THE
        ACCESS TO AND USE OF THE SERVICES, EXCEED THE LESSER OF (i) $100 OR (ii)
        TO THE TOTAL AMOUNT YOU PAID TO COMPANY IN FEES OVER THE SIX (6) MONTHS
        IMMEDIATELY PRECEDING THE CLAIM.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
        }}
      >
        <span style={{ textTransform: "uppercase" }}>
          Some jurisdictions do not allow the exclusion of certain warranties
          and limitations of liability provided in this Section. If you are in
          such a jurisdiction, some of the above limitations and disclaimers may
          not apply to you. To the extent we may not, as a matter of
        </span>
        <span style={{ textTransform: "uppercase" }}>&nbsp;</span>
        <span style={{ textTransform: "uppercase" }}>applicable</span>
        <span style={{ textTransform: "uppercase" }}>&nbsp;</span>
        <span style={{ textTransform: "uppercase" }}>
          law, disclaim any implied warranty or limit our liabilities, the scope
          and duration of such warranty and the extent of our liability will be
          the minimum permitted by applicable law.
        </span>
        <strong>
          <em>
            <span style={{ textTransform: "uppercase" }}>&nbsp;</span>
          </em>
        </strong>
      </p>
      <ol start={10} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "18pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            fontFamily: "Times New Roman Bold",
            fontWeight: "bold",
            fontStyle: "italic",
            fontVariant: "small-caps",
          }}
        >
          <span style={{ fontStyle: "normal" }}>Communications</span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        Company may communicate with you using email, phone calls, chatbots and
        text messages, including autodialed or prerecorded calls and text
        messages, at any email address or telephone number that you provide us,
        to: (a) notify you regarding your Account; (b) provide customer support;
        (c) troubleshoot problems with your Account; (d) resolve a dispute; (e)
        collect a debt; (f) poll your opinions through surveys or
        questionnaires; or (g) as otherwise necessary to service your account or
        enforce these Terms of Service, our policies, applicable law, or any
        other agreement we may have with you.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        Company may offer you the ability to receive texts and alerts (e.g.,
        multi-factor authentication) via mobile number, which you may elect to
        sign up for. By signing up, you provide your consent to receive mobile
        messages from or on behalf of us at the mobile number you&rsquo;ve
        provided. You understand that consent is not a condition of purchase.
        Message and data rates may apply. If you would like to be removed from
        the Company text list, you must reply STOP to any mobile message. If at
        any time you intend to stop using the mobile telephone number that has
        been used to subscribe to text messaging, including canceling your
        service plan or selling or transferring the phone number to another
        party, you agree that you will complete the user opt-out process set
        forth above prior to ending your use of the mobile telephone number. You
        understand and agree that your agreement to do so is a material part of
        these Terms of Service. You further agree that, if you discontinue the
        use of your mobile telephone number without notifying us of such change,
        you agree that you will be responsible for all costs (including
        attorneys&rsquo; fees) and liabilities incurred by us or any party that
        assists in the delivery of the mobile messages, as a result of claims
        brought by individual(s) who are later assigned that mobile telephone
        number. This duty and agreement shall survive any cancellation or
        termination of your Account or these Terms of Service.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        YOU AGREE THAT YOU SHALL INDEMNIFY, DEFEND, AND HOLD US HARMLESS FROM
        ANY CLAIM OR LIABILITY RESULTING FROM YOUR FAILURE TO NOTIFY US OF A
        CHANGE IN THE INFORMATION YOU HAVE PROVIDED, INCLUDING ANY CLAIM OR
        LIABILITY UNDER THE TELEPHONE CONSUMER PROTECTION ACT, 47 U.S.C. &sect;
        227, et seq., OR SIMILAR STATE AND FEDERAL LAWS, AND ANY REGULATIONS
        PROMULGATED THEREUNDER RESULTING FROM US ATTEMPTING TO CONTACT YOU AT
        THE MOBILE TELEPHONE NUMBER YOU PROVIDED.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        Company may route phone and text communications through a third-party
        service provider, and we or the service provider may record telephone
        conversations or chatbot exchanges you have with Company or its agents
        for quality control and training purposes or for our protection.
      </p>
      <ol start={11} type="1" style={{ margin: 0, padding: "0pt" }}>
        <li
          style={{
            marginLeft: "18pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontStyle: "normal",
              fontVariant: "small-caps",
            }}
          >
            Dispute Resolution
          </span>
          <ol
            type="A"
            style={{ marginRight: "0pt", marginLeft: "0pt", padding: "0pt" }}
          >
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Mandatory Arbitration of Disputes.
              </span>
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  marginRight: "0pt",
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontStyle: "normal",
                  marginRight: "0pt",
                }}
              >
                We each agree that any dispute, claim, or controversy arising
                out of or relating to these Terms of Service or the breach,
                termination, enforcement, interpretation, or validity thereof or
                the use of the Services (collectively, &ldquo;Disputes&rdquo;)
                will be resolved solely by binding, individual arbitration and
                not in a class, representative or consolidated action or
                proceeding. You and Company agree that the U.S. Federal
                Arbitration Act governs the interpretation and enforcement of
                these Terms of Service, and that you and Company are each
                waiving the right to a trial by jury or to participate in a
                class action. This arbitration provision shall survive
                termination of these Terms of Service.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Exceptions.
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;As limited exceptions to Section 11.a. above: (i) either
                party may seek to resolve a Dispute in small claims court if it
                qualifies; and (ii) each party retains the right to seek
                injunctive or other equitable relief from a court to prevent (or
                enjoin) the infringement or misappropriation of its respective
                intellectual property rights.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Conducting Arbitration and Arbitration Rules.
              </span>
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  marginRight: "0pt",
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontStyle: "normal",
                  marginRight: "0pt",
                }}
              >
                The arbitration will be conducted by the American Arbitration
                Association (&ldquo;AAA&rdquo;) under its Consumer Arbitration
                Rules (the &ldquo;AAA Rules&rdquo;) then in effect, except as
                modified by these Terms of Service. The AAA Rules are available
                at www.adr.org or by calling 1-800-778-7879. A party who wishes
                to start arbitration must submit a written Demand for
                Arbitration to AAA and give notice to the other party as
                specified in the AAA Rules. The AAA provides a form Demand for
                Arbitration at
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;
              </span>
              <a href="http://www.adr.org" style={{ textDecoration: "none" }}>
                <u>
                  <span
                    style={{
                      fontWeight: "normal",
                      fontStyle: "normal",
                      color: "#0563c1",
                    }}
                  >
                    www.adr.org
                  </span>
                </u>
              </a>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                .
              </span>
            </li>
            <li
              style={{
                marginLeft: "34.67pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "1.33pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Times New Roman Bold",
                  fontWeight: "normal",
                  fontStyle: "normal",
                }}
              >
                Arbitration Costs.&nbsp;
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                Payment of all filing, administration, and arbitrator fees will
                be governed by the AAA Rules, and each party shall bear its own
                costs and expenses of arbitration, including legal fees.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Injunctive and Declaratory Relief.&nbsp;
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                Except as provided in Section 11.b. above, the arbitrator shall
                determine all issues of liability on the merits of any claim
                asserted by either party and may award declaratory or injunctive
                relief only in favor of the individual party seeking relief and
                only to the extent necessary to provide relief warranted by that
                party&rsquo;s individual claim. To the extent that you or we
                prevail on a claim and seek public injunctive relief (that is,
                injunctive relief that has the primary purpose and effect of
                prohibiting unlawful acts that threaten future injury to the
                public), the entitlement to and extent of such relief must be
                litigated in a civil court of competent jurisdiction and not in
                arbitration. The parties agree that litigation of any issues of
                public injunctive relief shall be stayed pending the outcome of
                the merits of any individual claims in arbitration.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Class Action Waiver.&nbsp;
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                YOU AND COMPANY AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST
                THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
                PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING. Further, if the parties&rsquo;
                Dispute is resolved through arbitration, the arbitrator may not
                consolidate another person&rsquo;s claims with your claims, and
                may not otherwise preside over any form of a representative or
                class proceeding. If this specific provision is found to be
                unenforceable, then the entirety of this Dispute Resolution
                section shall be null and void.
              </span>
            </li>
          </ol>
        </li>
        <li
          style={{
            marginLeft: "18pt",
            marginBottom: "6pt",
            lineHeight: "normal",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontStyle: "normal",
              fontVariant: "small-caps",
            }}
          >
            Miscellaneous Terms
          </span>
          <ol
            type="A"
            style={{ marginRight: "0pt", marginLeft: "0pt", padding: "0pt" }}
          >
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                These Terms May Change.
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;We reserve the right, at our sole discretion, to update,
                change, modify, or replace any part of these Terms of Service by
                posting updates and changes to the Site. We may elect to notify
                you of such changes by mail, email, posting of modified Terms of
                Service, or some other similar manner. In the event of any
                material changes to the Terms of Service, you will be prompted
                to click on &quot;accept&quot; or &quot;agree&quot; to this
                Agreement. However, it is your responsibility to check the Site
                regularly for changes to these Terms of Service. Your continued
                use of or access to the Site or the Services following the
                posting of any changes to these Terms of Service constitutes
                acceptance of those changes.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Severability.
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;In the event that any provision of these Terms of Service
                is determined to be unlawful, void or unenforceable, such
                provision shall nonetheless be enforceable to the fullest extent
                permitted by applicable law, and the unenforceable portion shall
                be deemed to be severed from these Terms of Service, such
                determination shall not affect the validity and enforceability
                of any other remaining provisions.
              </span>
            </li>
            <li
              style={{
                marginLeft: "34pt",
                marginBottom: "6pt",
                textAlign: "justify",
                padding: "2pt",
              }}
            >
              <span style={{ fontFamily: "Times New Roman Bold" }}>
                Termination.
              </span>
              <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
                &nbsp;These Terms of Service are effective unless and until
                terminated by either you or us. You may terminate these Terms of
                Service at any time by notifying us that you no longer wish to
                use the Services or when you cease using our Services.
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
        }}
      >
        We are free to terminate (or suspend access to) your use of the Services
        (or any part thereof) or your Account if (i) you fail, or we suspect
        that you have failed, to comply with any term or provision of these
        Terms of Service, or (ii) for any other reason in our sole discretion.
        We also may terminate these Terms of Service at any time without notice.
        Even after your right to use the Services is terminated, the obligations
        and liabilities of the parties incurred prior to the termination date
        shall survive the termination, and the Terms of Service will remain
        enforceable against you.
      </p>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "54pt",
          marginBottom: "6pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        Provisions that, by their nature, should survive termination of these
        Terms of Service shall survive termination. By way of example, but
        without limitation, all of the following will survive termination: any
        obligation you have to pay us or indemnify us, any limitations on our
        liability, and any terms regarding ownership or intellectual property
        rights.
      </p>
      <ol start={4} type="A" style={{ margin: "0pt", padding: "0pt" }}>
        <li
          style={{
            marginLeft: "52.67pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            padding: "1.33pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span style={{ fontFamily: "Times New Roman Bold" }}>
            No Assignment of the Terms of Service.
          </span>
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontWeight: "normal",
              fontStyle: "normal",
            }}
          >
            &nbsp;
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            You may not assign these Terms of Service to any other party. We may
            assign these Terms of Service or delegate any or all of our rights
            and responsibilities under these Terms of Service to any third
            parties, without notice to you.
          </span>
        </li>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            padding: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span style={{ fontFamily: "Times New Roman Bold" }}>
            Governing Law and Jurisdiction.&nbsp;
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            These Terms of Service and all disputes arising out of or relating
            to the Terms of Service shall be governed by, construed, and
            enforced in accordance with the laws of the State of New York in the
            United States, without regard to its conflict of laws principles.
            These disputes will be resolved exclusively in the federal and state
            courts in the State of New York, and you and we consent to personal
            jurisdiction in those courts.&nbsp;
          </span>
        </li>
        <li
          style={{
            marginLeft: "52pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            padding: "2pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span style={{ fontFamily: "Times New Roman Bold" }}>Waiver.</span>
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontWeight: "normal",
              fontStyle: "normal",
            }}
          >
            &nbsp;
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            No delay or omission by us in exercising any rights or remedies
            thereunder shall impair such right or remedy or be construed as a
            waiver of any such right or remedy. Any single or partial exercise
            of a right or remedy by us shall not preclude further exercise or
            any right or remedy by us. No waiver by us shall be valid unless in
            writing signed by us.
          </span>
        </li>
        <li
          style={{
            marginLeft: "52.67pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            padding: "1.33pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span style={{ fontFamily: "Times New Roman Bold" }}>Headings.</span>
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontWeight: "normal",
              fontStyle: "normal",
            }}
          >
            &nbsp;
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            The headings used in the Terms of Service are included for
            convenience only and will not limit or otherwise affect these Terms
            of Service.
          </span>
        </li>
        <li
          style={{
            marginLeft: "53.33pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            padding: "0.67pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span style={{ fontFamily: "Times New Roman Bold" }}>
            Entire Agreement.
          </span>
          <span
            style={{
              fontFamily: "Times New Roman Bold",
              fontWeight: "normal",
              fontStyle: "normal",
            }}
          >
            &nbsp;
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            These Terms of Service and any policies or operating rules posted by
            us on this website or in respect of the Services constitute the
            complete and exclusive agreement and understanding between you and
            us related to the Services and supersede any prior or
            contemporaneous agreements, communications, and proposals, whether
            oral or written, between you and us (including, but not limited to,
            any prior versions of the Terms of Service). Any ambiguities in the
            interpretation of these Terms of Service shall not be construed
            against the drafting party.
          </span>
        </li>
        <li
          style={{
            marginLeft: "48.67pt",
            marginBottom: "6pt",
            textAlign: "justify",
            lineHeight: "normal",
            padding: "5.33pt",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <span style={{ fontFamily: "Times New Roman Bold" }}>
            Contact Information.
          </span>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;Questions about the Terms of Service should be sent to us
            at&nbsp;
          </span>
          <a href="mailto:admin@heylari.com" style={{ textDecoration: "none" }}>
            <u>
              <span
                style={{
                  fontWeight: "normal",
                  fontStyle: "normal",
                  color: "#0563c1",
                }}
              >
                info@heylari.com
              </span>
            </u>
          </a>
          <span style={{ fontWeight: "normal", fontStyle: "normal" }}>
            &nbsp;or 251 Little Falls Drive, Wilmington, DE 19808.
          </span>
        </li>
      </ol>
    </>
  );
};
