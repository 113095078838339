import React from "react";
import { Layout } from "antd";
import "./AppFooter.override.css";
import { env } from "../../../../type/AppLimits";
const conf = require("../../../../config/config.json")[env];

const { Footer } = Layout;

export const AppFooter = () => {
  return (
    <Footer className="footer">
      <div className="footerContent">
        Copyright ©LARI LLC. All rights reserved
      </div>
      <div className="footerContent">Beta version: {conf.version}</div>
    </Footer>
  );
};
