import { ENV_CFG } from "../.env";

export const UPLOAD_LIMIT: number = 1000;

export const DEF_PAGE_SIZE: number = 20;

export const RECORDS_PAGE_SIZE: number = 10;

export const F1ST_PAGE: number = 1;

export const env = ENV_CFG ?? "local";
