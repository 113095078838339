import styles from "./Home.module.css";
import "./Home.override.css";
import "animate.css";
import { ParticleComponent } from "./ParticleComponent/ParticleComponent";
import { SlideComponent } from "./SlideComponent/SlideComponent";

export const HomeWhatIsLari = () => {
  return (
    <div className={styles.whatIsLariSection}>
      <ParticleComponent />
      <SlideComponent />
    </div>
  );
};
