import React, { useEffect, useState } from "react";
import "../auth-layout/AuthLayout.override.css";
import "./SignUp.override.css";
import { Button, Form } from "antd";
import { AppButton } from "../../../common/components/button/AppButton";
import { AuthLayout } from "../auth-layout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { Terms } from "../terms/Terms";
import { StepsRender } from "./sign-up-steps/StepsRender";
import { useAppSelector } from "../../../../store/hooks";
import { mapSignUpRequest } from "../../utils/sign-up.mapper";
import { ERoutes } from "../../../../type/eroutes";

export const SignUp = ({
  submit,
}: {
  submit: (params: any, success: () => void) => void;
}) => {
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.auth);

  const [form] = Form.useForm();

  const [currentStep, setCurrentStep] = useState(1);
  const [isTerms, setTerms] = useState(false);
  const [termsRead, setTermsAreRead] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleTerms = () => {
    setTerms(true);
  };

  const handleStepChange = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = () => {
    const data = mapSignUpRequest(form.getFieldsValue(true));
    submit(data, () => {
      navigate(ERoutes.signIn);
    });
  };

  const isFirstStep = () => currentStep === 1;

  const isLastStep = () => currentStep === 3;

  const scrollCheck = (el: any) => {
    if (
      el.target.scrollHeight - el.target.scrollTop <=
      el.target.clientHeight + 0.5
    ) {
      setTermsAreRead(true);
    }
  };

  const onClose = () => {
    setTerms(false);
  };

  const onClick = () => {
    setTerms(false);
    setChecked(!checked);
  };

  useEffect(() => {
    form.setFieldsValue({ acceptTermsOfConditions: checked });
  }, [checked, form]);

  return (
    <AuthLayout
      className={isFirstStep() ? "equalSideLayout" : "diffSideLayout"}
      pageTitle="Create account"
      displayLeft={isFirstStep()}
      displayLeftMobile={isFirstStep()}
      displayRight={!isTerms}
      sideTitle="Welcome back"
      sideContent="Already a user? Click the button below to access your account"
      sideButton={
        <Link
          data-testid="sign-in-link"
          to={ERoutes.signIn}
          className="createAccountLink"
        >
          <Button className="createAccountBtn">Sign In</Button>
        </Link>
      }
      sideContainerClassName="sideSignUp"
    >
      {isTerms ? (
        <Terms
          onClose={onClose}
          onScroll={scrollCheck}
          termsRead={termsRead}
          checked={checked}
          onClick={onClick}
        />
      ) : (
        <>
          <div className="stepsCount">
            {!isFirstStep() && (
              <div
                className="stepBack"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                BACK
              </div>
            )}
            <span className="counter">STEP {currentStep}/3</span>
          </div>
          <Form
            data-testid="sign-up-form"
            form={form}
            name="signIn"
            onFinish={isLastStep() ? handleSubmit : handleStepChange}
            autoComplete="off"
            className="authForm"
          >
            <StepsRender
              currentStep={currentStep}
              form={form}
              toggleTerms={toggleTerms}
              checked={checked}
            />
            {isLastStep() && (
              <AppButton
                className="submitBtn"
                text="Complete Registration"
                htmlType="submit"
                disabled={isLoading}
              />
            )}
          </Form>
        </>
      )}
    </AuthLayout>
  );
};
