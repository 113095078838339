import { createSlice } from "@reduxjs/toolkit";
import { emailApi } from "../../../api/email.api";
import { openModal } from "../../common/slices/modal.slice";
import { IEmailData, IEmailState } from "../interfaces/email";

const initialState: IEmailState = {};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    sendEmailLoading(state, action) {
      state.sendEmailLoading = action.payload;
    },
    sendEmail(state) {
      state.sendEmailLoading = false;
    },
    sendMeEmailLoading(state, action) {
      state.sendMeEmailLoading = action.payload;
    },
    sendMeEmail(state) {
      state.sendMeEmailLoading = false;
    },
  },
});

export const sendContactUsEmail =
  (comment: string, email: string, name: string, data: File) =>
  async (dispatch: any) => {
    dispatch(sendEmailLoading(true));
    try {
      const formData: FormData = new FormData();
      formData.append("file", data);
      await emailApi.sendContactUsEmail(comment, email, name, formData);
      dispatch(sendEmail());
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent: "Contact US Email sent successfully",
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
    } catch (e) {
      dispatch(sendEmailLoading(false));
    }
  };

export const sendContactMeEmail =
  (data: IEmailData) => async (dispatch: any) => {
    dispatch(sendMeEmailLoading(true));
    try {
      await emailApi.sendContactMeEmail(data);
      dispatch(sendMeEmail());
      dispatch(
        openModal({
          modalTitle: "Success",
          modalContent: "Contact Email sent successfully",
          buttonLabel: "Close",
          closable: false,
          buttonVisible: true,
        }),
      );
    } catch (e) {
      dispatch(sendMeEmailLoading(false));
    }
  };

const { actions, reducer } = emailSlice;

export const { sendEmailLoading, sendEmail, sendMeEmailLoading, sendMeEmail } =
  actions;

export default reducer;
