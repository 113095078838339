import React from "react";

export interface IAuthState {
  token: string;
  isLoading: boolean;
  userLoading: boolean;
  termsLoaded: boolean;
  terms: string;
  user: {
    accept_terms_of_conditions: boolean;
    email: string;
    firstName: string;
    fullName: string;
    icon_id: string;
    id: string;
    lastName: string;
    last_calc_time: string;
    organisation_type: string;
    role: string;
    phone_number: string;
    company_name: string;
    job_title: string;
  };
}

export interface IUser {
  accept_terms_of_conditions: boolean;
  email: string;
  firstName: string;
  fullName: string;
  icon_id: string;
  id: string;
  lastName: string;
  last_calc_time: string;
  organisation_type: string;
  role: string;
  phone_number: string;
  company_name: string;
  job_title: string;
}

export interface IAuthLayout {
  displayLeft?: boolean;
  displayRight?: boolean;
  displayLeftMobile?: boolean;
  children: React.ReactNode;
  pageTitle: string;
  sideTitle?: string;
  sideContent?: string;
  sideButton?: JSX.Element;
  className: string;
  sideContainerClassName?: string;
}

export interface IModalState {
  isOpened: boolean;
  modalContent: any;
  modalTitle?: string;
  closable?: boolean;
  buttonLabel?: string;
  buttonVisible: boolean;
  isMain?: boolean;
}

export enum EOptionsValue {
  Policyholder = "Policyholder",
  Broker = "Broker",
  Insurer = "Insurer",
  Reinsurer = "Reinsurer",
  Other = "Other",
}

export interface ISignUpRequest {
  acceptTermsOfConditions: boolean;
  companyName: string;
  email: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  organisationType: EOptionsValue;
  otherType: string;
  password: string;
  phoneNumber: string;
}

export interface IResetPassRequest {
  key: string;
  password: string;
}
