import React from "react";
import { Button } from "antd";
import { IAppButton } from "../../interfaces/inputs";

export const AppButton = ({
  text,
  href,
  className,
  type = "default",
  htmlType = "button",
  onClick,
  disabled,
}: IAppButton) => {
  return (
    <Button
      className={className}
      htmlType={htmlType}
      type={type}
      href={href}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
