import { createSlice } from "@reduxjs/toolkit";
import { csvApi } from "../../../api/csv.api";
import { ICsvState } from "../interfaces/csv";
import { DownloadHelper } from "../utils/download.helper";
import {
  IDeleteIds,
  IFetchList,
  IUploadImportWithGroup,
} from "../interfaces/dashboardCommon";

const initialState: ICsvState = {};

export const csvSlice = createSlice({
  name: "csv",
  initialState,
  reducers: {
    importRecordLoading(state, action) {
      state.importRecordLoading = action.payload;
    },
    templateLoading(state, action) {
      state.templateLoading = action.payload;
    },
    importRecords(state) {
      state.importRecordLoading = false;
    },
    template(state) {
      state.templateLoading = false;
    },
    fetchBatchUploadLoading(state, action) {
      state.fetchBatchUploadLoading = action.payload;
    },
    deleteBatchUploadsLoading(state, action) {
      state.deleteBatchUploadsLoading = action.payload;
    },
    importBatchUploadsLoading(state, action) {
      state.importBatchUploadsLoading = action.payload;
    },
    importBatchUpload(state, action) {
      return {
        ...state,
        batchUploadList: state.batchUploadList,
        importBatchUploadsLoading: false,
      };
    },
    deleteBatchUpload(state, action) {
      return {
        ...state,
        batchUploadList: state.batchUploadList?.filter(
          (item: any) => action.payload.ids_list.indexOf(item.id) === -1,
        ),
        deleteBatchUploadsLoading: false,
      };
    },
    getSingleBatchUpload(state, action) {
      state.batchUpload = action.payload.batchUploadItem;
      state.fetchSingleBatchUploadLoading = false;
    },
    fetchBatchUploadsInProgress(state, action) {
      state.batchUploadsInProgress = action.payload;
      state.fetchBatchUploadsInProgressLoading = false;
    },
    clearBatchUploadsInProgress(state, action) {
      state.batchUploadsInProgress = undefined;
    },
    fetchBatchUploadsInProgressLoading(state, action) {
      state.fetchBatchUploadsInProgressLoading = action.payload;
    },
    fetchSingleLoading(state, action) {
      state.fetchSingleBatchUploadLoading = action.payload;
    },
    getBatchUploadList(state, action) {
      state.batchUploadList = action.payload.list.map(
        (item: { id: number }) => ({
          ...item,
          key: item.id,
          version: 1,
        }),
      );
      state.totalBatchUploads = action.payload.totalElements;
      state.fetchBatchUploadLoading = false;
    },
  },
});

export const fetchSingleBatchUpload = (id: number) => async (dispatch: any) => {
  dispatch(fetchSingleLoading(true));
  try {
    const res = await csvApi.getSingleBatchUpload(id);
    dispatch(getSingleBatchUpload(res));
    return res.recordList;
  } catch (e) {
    dispatch(fetchSingleLoading(false));
    return [];
  }
};

export const getBatchUploadsInProgress = () => async (dispatch: any) => {
  dispatch(fetchBatchUploadsInProgressLoading(true));
  try {
    const res = await csvApi.getBatchUploadsInProgress();
    dispatch(fetchBatchUploadsInProgress(res));
    return res.idsList;
  } catch (e) {
    dispatch(fetchBatchUploadsInProgressLoading(false));
    return [];
  }
};

export const cleanBatchUploadsInProgress = () => async (dispatch: any) => {
  dispatch(fetchBatchUploadsInProgressLoading(true));
  try {
    let res;
    dispatch(clearBatchUploadsInProgress(res));
  } catch (e) {
    dispatch(fetchBatchUploadsInProgressLoading(false));
  }
};

export const importBatchUploads =
  (data: IUploadImportWithGroup, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(importBatchUploadsLoading(true));
    try {
      const res = await csvApi.importBatchUploads(data);
      dispatch(importBatchUpload(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(importBatchUploadsLoading(false));
    }
  };

export const deleteBatchUploads =
  (data: IDeleteIds, success?: () => void) => async (dispatch: any) => {
    dispatch(deleteBatchUploadsLoading(true));
    try {
      const res = await csvApi.deleteBatchUploads(data);
      dispatch(deleteBatchUpload(res));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(deleteBatchUploadsLoading(false));
    }
  };

export const fetchBatchUploadItems =
  (data: IFetchList) => async (dispatch: any) => {
    dispatch(fetchBatchUploadLoading(true));
    try {
      const res = await csvApi.getBatchUploadList(data);
      dispatch(getBatchUploadList(res));
    } catch (e) {
      dispatch(fetchBatchUploadLoading(false));
    }
  };

export const importRecord =
  (file: File, success: (res: any) => void) => async (dispatch: any) => {
    dispatch(importRecordLoading(true));
    try {
      const formData: FormData = new FormData();
      formData.append("file", file);
      const res = await csvApi.importRecord(formData);
      dispatch(importRecords());
      success(res);
    } catch (e) {
      dispatch(importRecordLoading(false));
    }
  };

export const importBatchRecords =
  (file: File, success: (res: any) => void) => async (dispatch: any) => {
    try {
      const formData: FormData = new FormData();
      formData.append("file", file);
      const res = await csvApi.importBatchRecords(formData);
      success(res);
    } catch (e) {
      console.error(e);
    }
  };

export const getTemplate = () => async (dispatch: any) => {
  dispatch(templateLoading(true));
  try {
    const res: any = await csvApi.getTemplate();
    DownloadHelper.downloadFile(res.data, "Batch Upload Sample.csv");
    dispatch(template());
  } catch (e) {
    dispatch(templateLoading(false));
  }
};

export const getNewBatchTemplate = () => async (dispatch: any) => {
  dispatch(templateLoading(true));
  try {
    const res: any = await csvApi.getNewBatchTemplate();
    DownloadHelper.downloadFile(res.data, "Batch Upload Sample v2.csv");
    dispatch(template());
  } catch (e) {
    dispatch(templateLoading(false));
  }
};

const { actions, reducer } = csvSlice;

export const {
  importRecords,
  getBatchUploadList,
  fetchBatchUploadLoading,
  importRecordLoading,
  templateLoading,
  template,
  deleteBatchUpload,
  deleteBatchUploadsLoading,
  importBatchUpload,
  importBatchUploadsLoading,
  fetchSingleLoading,
  getSingleBatchUpload,
  fetchBatchUploadsInProgress,
  fetchBatchUploadsInProgressLoading,
  clearBatchUploadsInProgress,
} = actions;

export default reducer;
