export enum ERoutes {
  home = "/",
  signIn = "/sign-in",
  signUp = "/sign-up",
  forgotPassword = "/forgot-password",
  resetPassword = "/reset-password",
  admin = "/*",
  dashboard = "/dashboard",
  contactUs = "/contact-us",
  knowledgeBank = "/knowledge-bank",
  records = "/records",
  usersv = "/users/:id",
  record = "/records/:id",
  recordReport = "/records/:id/report",
  groups = "/groups",
  group = "/groups/:id",
  groupReport = "/groups/:id/report",
  profile = "/profile",
  checkout = "/checkout",
  adminPage = "/admin",
  adminSector = "/admin/sector/:id/:naics",
  analytics = "/analytics",
  analytic = "/analytics/:naics/:zip",
}
