import React from "react";
import "./AuthLayout.override.css";
import { Col, Layout, Row } from "antd";
import authStyles from "../Auth.module.css";
import logoDesktop from "../../../../assets/icons/BetaLogo.svg";
import styles from "./AuthLayout.module.css";
import logoMobile from "../../../../assets/images/logoMobile.png";
import { Link } from "react-router-dom";
import { IAuthLayout } from "../../interfaces/authCommon";
import { ERoutes } from "../../../../type/eroutes";

export const AuthLayout = ({
  displayLeft,
  displayRight,
  displayLeftMobile,
  children,
  pageTitle,
  sideTitle,
  sideContent,
  sideButton,
  className,
  sideContainerClassName,
}: IAuthLayout) => {
  const renderLeftWrapper = (props: { mobile: boolean | undefined }) => (
    <Col className={props.mobile ? "leftWrapper desktop" : "leftWrapperMobile"}>
      <div className={styles.leftWrapperContainer}>
        <Link to={ERoutes.home} className="logoDesktop">
          <img
            className={authStyles.logoImg}
            src={logoDesktop}
            alt="Hey Lari"
          />
          {/* <span className={authStyles.logoTitle}>LARI</span> */}
        </Link>

        {displayLeft && (
          <div className={styles.leftWrapperContent}>
            <div className={sideContainerClassName}>
              <h2 className={styles.leftWrapperTitle}>{sideTitle}</h2>
              <span className={styles.leftWrapperText}>{sideContent}</span>
            </div>
            {sideButton}
          </div>
        )}
      </div>
    </Col>
  );

  return (
    <Layout className={className}>
      <Row className="pageRow">
        {renderLeftWrapper({ mobile: false })}
        <Col className="rightWrapper">
          <div className={styles.rightWrapperContainer}>
            {displayRight ? (
              <>
                <Link to="/" className="logoMobile">
                  <img
                    className={authStyles.logoImg}
                    src={logoMobile}
                    alt="Hey Lari"
                  />
                  <span className={authStyles.logoTitle}>LARI</span>
                </Link>
                <div className={styles.rightWrapperContent}>
                  <h2 className="pageTitle">{pageTitle}</h2>
                  {children}
                </div>
              </>
            ) : (
              <>{children}</>
            )}
          </div>
        </Col>
        {displayLeftMobile && renderLeftWrapper({ mobile: true })}
      </Row>
    </Layout>
  );
};
