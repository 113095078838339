import { openModal } from "../../slices/modal.slice";

export function openErrorMessage(errorMessage: string) {
  return openModal({
    modalTitle: "Error",
    modalContent: errorMessage,
    buttonLabel: "Close",
    closable: false,
    buttonVisible: true,
  });
}
