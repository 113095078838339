import React from "react";
import { SignUpStep1 } from "./SignUpStep1";
import { SignUpStep2 } from "./SignUpStep2";
import { SignUpStep3 } from "./SignUpStep3";
import { IStepsMap } from "../../../interfaces/stepsMap";

export const StepsRender = (props: {
  currentStep: number;
  form: any;
  toggleTerms: (event: any) => void;
  checked: boolean;
}) => {
  const stepsComponentMap: IStepsMap = {
    1: <SignUpStep1 form={props.form} />,
    2: <SignUpStep2 />,
    3: <SignUpStep3 toggleTerms={props.toggleTerms} checked={props.checked} />,
  };

  return <>{stepsComponentMap[props.currentStep]}</>;
};
