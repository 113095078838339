import React, { useEffect, useRef } from "react";
import styles from "./AppVideo.module.css";
import "./AppVideo.override.css";
import PlayButton from "../../../../assets/icons/playButton.svg";
import PlayIcon from "../../../../assets/icons/playIcon.svg";
import PauseIcon from "../../../../assets/icons/pauseIcon.svg";
import VolumeIcon from "../../../../assets/icons/volumeIcon.svg";
import FullscreenIcon from "../../../../assets/icons/fullscreenIcon.svg";
import MuteIcon from "../../../../assets/icons/muteIcon.svg";
import useVideoPlayer from "../../utils/useVideoPlayer";
import clockImage from "../../../../assets/images/poster.png";

export const AppVideo = (props: { url: string; className?: string }) => {
  const videoElement = useRef<HTMLVideoElement | null>(null);
  const {
    playerState,
    togglePlay,
    playFromBeginning,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoVolume,
    toggleMute,
    toggleFullscreen,
    onMouseEnter,
    onMouseLeave,
  } = useVideoPlayer(videoElement);

  const durationControl = useRef<HTMLInputElement>(null);
  const volumeControl = useRef<HTMLInputElement>(null);
  const SPS = " ";

  useEffect(() => {
    const dc: HTMLInputElement | null = durationControl.current;
    const vc: HTMLInputElement | null = volumeControl.current;
    if (dc && vc) {
      const setProgressBackgroundSize = () => {
        dc?.style.setProperty(
          "--background-size",
          `${isNaN(playerState.progress) ? 0 : playerState.progress}%`,
        );
      };

      const setVolumeBackgroundSize = () => {
        vc?.style.setProperty("--background-size", `${playerState.volume}%`);
      };

      setProgressBackgroundSize();
      setVolumeBackgroundSize();

      dc?.addEventListener("input", setProgressBackgroundSize);
      vc?.addEventListener("input", setVolumeBackgroundSize);

      return () => {
        dc?.removeEventListener("input", setProgressBackgroundSize);
        vc?.removeEventListener("input", setVolumeBackgroundSize);
      };
    }
  }, [playerState.progress, playerState.volume]);

  const display = (seconds: number) => {
    const format = (val: number) => `0${Math.floor(val)}`.slice(-2);
    const minutes = (seconds % 3600) / 60;

    return [minutes, seconds % 60].map(format).join(":");
  };

  return (
    <div
      className={"videoWrapper" + SPS + props.className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={playFromBeginning}
    >
      <div className={styles.videoPlayButton} onClick={togglePlay}>
        {!playerState.isActive && (
          <img className={styles.playButton} src={PlayButton} alt="Play" />
        )}
      </div>
      <video
        id="videoEl"
        className={styles.videoContainer + props.className}
        src={props.url}
        onTimeUpdate={handleOnTimeUpdate}
        ref={(element) => (videoElement.current = element)}
        poster={clockImage}
        muted={playerState.isHovered && playerState.currentTime < 30}
        preload="none"
        playsInline={true}
        disablePictureInPicture={true}
        width="100%"
        height="100%"
        loop
      />
      <div className={styles.controlsContainer}>
        <div className={styles.controls}>
          <div onClick={togglePlay}>
            {!playerState.isActive ? (
              <img className={styles.playIcon} src={PlayIcon} alt="Play" />
            ) : (
              <img className={styles.pauseIcon} src={PauseIcon} alt="Pause" />
            )}
          </div>
        </div>

        <div className={styles.timeControls}>
          <input
            type="range"
            min="0"
            max="100"
            value={isNaN(playerState.progress) ? 0 : playerState.progress}
            onChange={handleVideoProgress}
            className="durationControl"
            ref={durationControl}
          />
          <div className={styles.duration}>
            {display(playerState.currentTime)}
          </div>
        </div>
        <div className={styles.volumeControls}>
          <div className={styles.muteBtn} onClick={toggleMute}>
            {!playerState.isMuted ? (
              <img
                className={styles.volumeIcon}
                src={VolumeIcon}
                alt="Volume"
              />
            ) : (
              <img className={styles.muteIcon} src={MuteIcon} alt="Mute" />
            )}
          </div>
          <input
            type="range"
            min="0"
            max="100"
            value={playerState.volume}
            onChange={handleVideoVolume}
            className="volumeControl"
            ref={volumeControl}
          />
        </div>
        <div className={styles.fullScreen} onClick={toggleFullscreen}>
          <img
            className={styles.fullScreenIcon}
            src={FullscreenIcon}
            alt="Fullscreen"
          />
        </div>
      </div>
    </div>
  );
};

export default AppVideo;
