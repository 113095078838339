import React from "react";
import "../auth-layout/AuthLayout.override.css";
import styles from "./ForgotPassword.module.css";
import { Button, Form } from "antd";
import { AppInput } from "../../../common/components/input/AppInput";
import { AppButton } from "../../../common/components/button/AppButton";
import { Link, useNavigate } from "react-router-dom";
import { forgotPasswordFormControls } from "../../forms/forgotPassword";
import { AuthLayout } from "../auth-layout/AuthLayout";
import { useAppSelector } from "../../../../store/hooks";
import { ERoutes } from "../../../../type/eroutes";

export const ForgotPassword = ({
  submit,
}: {
  submit: (params: any, success: () => void) => void;
}) => {
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.auth);

  const onSubmit = (val: any) => {
    submit(val, () => {
      navigate(ERoutes.signIn);
    });
  };

  return (
    <AuthLayout
      className="equalSideLayout"
      pageTitle="Forgot Password"
      displayLeft={true}
      displayLeftMobile={true}
      displayRight={true}
      sideTitle="Create Account"
      sideContent="You're one step away from seeing the magic"
      sideButton={
        <Link to={ERoutes.signUp} className="createAccountLink">
          <Button className="createAccountBtn">New to Lari</Button>
        </Link>
      }
      sideContainerClassName="sideForgotPass"
    >
      <Link to={ERoutes.signIn} className={styles.backLink}>
        BACK
      </Link>
      <Form
        data-testid="forgot-password-form"
        name="forgotPassword"
        onFinish={onSubmit}
        autoComplete="off"
        className="authForm"
      >
        <AppInput config={forgotPasswordFormControls.email} />

        <AppButton
          className="submitBtn"
          text="Send"
          htmlType="submit"
          disabled={isLoading}
        />
      </Form>
    </AuthLayout>
  );
};
