import { IFormControl } from "../../common/interfaces/inputs";

export const signInFromControls: {
  email: IFormControl;
  password: IFormControl;
} = {
  email: {
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail",
        max: 100,
      },
      {
        required: true,
        message: "Please input your E-mail",
      },
    ],
    name: "email",
    placeholder: "Email",
    maxLength: 100,
  },
  password: {
    rules: [
      {
        required: true,
        message: "Please enter your password",
      },
      {
        min: 2,
        message: "Password must contain at least 2 characters",
      },
    ],
    name: "password",
    placeholder: "Password",
  },
};
