import { ApiClient } from "./apiClient";
import { IAdminState } from "../modules/admin/interfaces/admin";
import { IFetchList } from "../modules/dashboard/interfaces/dashboardCommon";

export const adminApi = {
  addNaicsCode: (data: IAdminState["naicsCodeItem"]) =>
    ApiClient.post({ path: "admin/naicsCode/", body: data }),

  getNaicsCode: (id: number) =>
    ApiClient.get({ path: "admin/naicsCode/" + id }),

  updateNaicsCode: (data: IAdminState["naicsCodeItem"], id: number) =>
    ApiClient.put({ path: "admin/naicsCode/" + id, body: data }),

  deleteNaicsCode: (id: number) =>
    ApiClient.delete({ path: "admin/naicsCode/" + id }),

  getNaicsCodeList: (id: number) =>
    ApiClient.get({ path: "admin/naicsCode/sector/" + id }),

  getSector: (id: number) => ApiClient.get({ path: "sector/" + id }),

  getSectorList: () => ApiClient.post({ path: "sector/list" }),

  getUserList: (data: IFetchList) =>
    ApiClient.post({ path: "user/list", body: data }),

  getModifiers: (id: number) =>
    ApiClient.get({ path: "admin/modifier/naicsCode/" + id }),

  getEvm: (id: number) => ApiClient.get({ path: "admin/evm/naicsCode/" + id }),

  updateModifier: (data: IAdminState["sourceItem"], id: number) =>
    ApiClient.put({ path: "admin/modifier/" + id, body: data }),

  updateEvm: (data: IAdminState["sourceItem"], id: number) =>
    ApiClient.put({ path: "admin/evm/" + id, body: data }),

  getSources: (id: number) =>
    ApiClient.get({ path: "admin/source/naicsCode/" + id }),

  updateSource: (data: IAdminState["sourceItem"], id: number) =>
    ApiClient.put({ path: "admin/source/" + id, body: data }),

  getStatistics: () => ApiClient.get({ path: "admin/statistic/" }),

  updateBivMix: (data: IAdminState["bivMix"]) =>
    ApiClient.put({ path: "admin/bivMix/", body: data }),

  checkRole: (data: boolean) =>
    ApiClient.put({ path: "authorization/updateRole?user=" + data }),

  getStatisticsReport: (data: any) =>
    ApiClient.post({ path: "admin/statistic/report", body: data }),

  getStatisticsReportPdf: (data: any) =>
    ApiClient.post({ path: "admin/statistic/report/pdf", body: data }),

  updateModifierList: (data: any) =>
    ApiClient.put({ path: "admin/modifier/updateList", body: data }),

  updateSourceList: (data: IAdminState["modifierList"][]) =>
    ApiClient.put({ path: "admin/source/updateList", body: data }),

  updateEvmList: (data: IAdminState["modifierList"][]) =>
    ApiClient.put({ path: "admin/evm/updateList", body: data }),

  getNoteByNaicsCode: (id: number) =>
    ApiClient.get({ path: "admin/note/naicsCode/" + id }),

  addNote: (data: IAdminState["note"]) =>
    ApiClient.post({ path: "admin/note/add", body: data }),

  updateNote: (data: IAdminState["note"], id: number) =>
    ApiClient.put({ path: "admin/note/update/" + id, body: data }),
};
