import { IFormControl } from "../../common/interfaces/inputs";
import { Regexp } from "../../common/utils/regex.helper";

export const signUpFormControls: {
  firstName: IFormControl;
  lastName: IFormControl;
  organisationType: IFormControl;
  otherType: IFormControl;
  companyName: IFormControl;
  jobTitle: IFormControl;
  phoneNumber: IFormControl;
  email: IFormControl;
  password: IFormControl;
  confirmPassword: IFormControl;
  acceptTermsOfConditions: IFormControl;
} = {
  firstName: {
    rules: [
      {
        required: true,
        message: "Please enter your name",
      },
      {
        pattern: Regexp.nameInput,
        message: "Please enter a valid name",
      },
    ],
    name: "firstName",
    placeholder: "Name",
    maxLength: 20,
  },
  lastName: {
    rules: [
      {
        required: true,
        message: "Please enter your surname",
      },
      {
        pattern: Regexp.nameInput,
        message: "Please enter a valid surname",
      },
    ],
    name: "lastName",
    placeholder: "Surname",
    maxLength: 20,
  },
  organisationType: {
    rules: [{ required: true, message: "Please select your organization" }],
    name: "organisationType",
    placeholder: "Organization type",
  },
  otherType: {
    rules: [
      {
        required: true,
        message: "Please write your type of organization",
      },
      {
        pattern: Regexp.textInput,
        message: "Please enter a valid type of organization",
      },
    ],
    name: "otherType",
    placeholder: "Write your type of organization",
    maxLength: 100,
  },
  companyName: {
    rules: [
      {
        pattern: Regexp.companyName,
        message: "Please enter a valid company name",
      },
    ],
    name: "companyName",
    placeholder: "Company name",
    maxLength: 100,
  },
  jobTitle: {
    rules: [
      {
        pattern: Regexp.textInput,
        message: "Please enter a valid job title",
      },
    ],
    name: "jobTitle",
    placeholder: "Job title",
    maxLength: 100,
  },
  phoneNumber: {
    rules: [
      {
        pattern: Regexp.phoneNumber,
        message: "Please enter a valid phone number",
      },
    ],
    name: "phoneNumber",
    placeholder: "Phone number",
    maxLength: 15,
  },
  email: {
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail",
      },
      {
        required: true,
        message: "Please input your E-mail",
      },
    ],
    name: "email",
    placeholder: "Email",
    maxLength: 100,
  },
  password: {
    rules: [
      {
        required: true,
        message: "Please input your password",
      },
      {
        min: 2,
        message: "Password must contain at least 2 characters",
      },
    ],
    name: "password",
    placeholder: "Password",
  },
  confirmPassword: {
    rules: [
      {
        required: true,
        message: "Please confirm your password",
      },
      {
        min: 2,
        message: "Password must contain at least 2 characters",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The two passwords that you entered do not match"),
          );
        },
      }),
    ],
    dependencies: "password",
    name: "confirmPassword",
    placeholder: "Confirm password",
  },
  acceptTermsOfConditions: {
    rules: [
      {
        required: true,
        message: "Please accept Terms of use",
        type: "boolean",
        transform: (value) => value || undefined,
      },
    ],
    name: "acceptTermsOfConditions",
    valuePropName: "checked",
  },
};
