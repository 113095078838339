import { createSlice } from "@reduxjs/toolkit";
import { csvApi } from "../../../api/csv.api";
import { ICsvRecordState } from "../interfaces/csv";
import { IDeleteIds, IFetchList } from "../interfaces/dashboardCommon";

const initialState: ICsvRecordState = {};

export const csvRecordSlice = createSlice({
  name: "csvRecord",
  initialState,
  reducers: {
    fetchBatchUploadLoading(state, action) {
      state.fetchBatchUploadLoading = action.payload;
    },
    getRecordsInBatchUpload(state, action) {
      state.batchUploadRecordsList = action.payload.list.map(
        (item: { id: number }) => ({
          ...item,
          key: item.id,
        }),
      );
      state.totalBatchUploadRecords = action.payload.totalElements;
      state.fetchBatchUploadLoading = false;
    },
    deleteRecordFromBatchUploadLoading(state, action) {
      state.deleteRecordFromBatchUploadLoading = action.payload;
    },
    deleteRecordsFromBatchUpload(state, action) {
      const pl: IDeleteIds = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        batchUploadRecordsList: state.batchUploadRecordsList?.filter(
          (item: any) => pl.idsList && pl.idsList.indexOf(item.id) === -1,
        ),
        deleteRecordFromBatchUploadLoading: false,
      };
    },
    getSingleBatchUploadRecord(state, action) {
      state.batchUploadRecord = action.payload.batchUploadItem;
      state.fetchSingleBatchUploadRecordLoading = false;
    },
    fetchSingleLoading(state, action) {
      state.fetchSingleBatchUploadRecordLoading = action.payload;
    },
    importOfBatchUploadLoading(state, action) {
      state.importOfBatchUploadLoading = action.payload;
    },
    importOfBatchUpload(state, action) {
      state.importOfBatchUploadLoading = false;
    },
    searchImportedRecords(state, action) {
      state.importedRecordsSearch = action.payload;
    },
  },
});

export const fetchBatchUploadRecords =
  (id: number, data: IFetchList) => async (dispatch: any) => {
    dispatch(fetchBatchUploadLoading(true));
    try {
      const res = await csvApi.getRecordsInBatchUpload(id, data);
      dispatch(getRecordsInBatchUpload(res));
      return res.list.map((item: any) => item.id);
    } catch (e) {
      dispatch(fetchBatchUploadLoading(false));
      return [];
    }
  };

export const fetchSingleBatchUploadRecord =
  (id: number) => async (dispatch: any) => {
    dispatch(fetchSingleLoading(true));
    try {
      const res = await csvApi.getSingleBatchUploadRecord(id);
      dispatch(getSingleBatchUploadRecord(res));
      return res.batchUploadItem;
    } catch (e) {
      dispatch(fetchSingleLoading(false));
      return {};
    }
  };

export const deleteRecordFromBatchUpload =
  (data: IDeleteIds, id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(deleteRecordFromBatchUploadLoading(true));
    try {
      await csvApi.deleteRecordsFromBatchUpload(data, id);
      dispatch(deleteRecordsFromBatchUpload(id));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(deleteRecordFromBatchUploadLoading(false));
    }
  };

export const importRecordOfBatchUpload =
  (data: IDeleteIds, id: number, success?: () => void) =>
  async (dispatch: any) => {
    dispatch(importOfBatchUploadLoading(true));
    try {
      await csvApi.importOfBatchUpload(data, id);
      dispatch(importOfBatchUpload(id));
      if (success) {
        success();
      }
    } catch (e) {
      dispatch(importOfBatchUploadLoading(false));
    }
  };

const { actions, reducer } = csvRecordSlice;

export const {
  deleteRecordsFromBatchUpload,
  deleteRecordFromBatchUploadLoading,
  getRecordsInBatchUpload,
  fetchBatchUploadLoading,
  importOfBatchUpload,
  importOfBatchUploadLoading,
  fetchSingleLoading,
  getSingleBatchUploadRecord,
  searchImportedRecords,
} = actions;

export default reducer;
