import React from "react";
import "../auth-layout/AuthLayout.override.css";
import { resetPasswordFormControls } from "../../forms/resetPassword";
import { Form } from "antd";
import { AppInput } from "../../../common/components/input/AppInput";
import { AppButton } from "../../../common/components/button/AppButton";
import { AuthLayout } from "../auth-layout/AuthLayout";
import { useAppSelector } from "../../../../store/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { mapResetPassRequest } from "../../utils/reset-pass.mapper";
import { ERoutes } from "../../../../type/eroutes";

export const ResetPassword = ({
  submit,
}: {
  submit: (params: any, success: () => void) => void;
}) => {
  const navigate = useNavigate();

  const params = new URLSearchParams(useLocation().search);
  const key = params.get("key");

  const { isLoading } = useAppSelector((state) => state.auth);

  const [form] = Form.useForm();

  const onSubmit = () => {
    const data = mapResetPassRequest(key, form.getFieldsValue(true));
    submit({ ...data }, () => {
      navigate(ERoutes.signIn);
    });
  };

  return (
    <AuthLayout
      className="equalSideLayout"
      pageTitle="Reset Password"
      displayLeft={false}
      displayRight={true}
    >
      <Form
        form={form}
        name="resetPassword"
        onFinish={onSubmit}
        autoComplete="off"
        className="authForm"
      >
        <AppInput config={resetPasswordFormControls.password} type="password" />

        <AppInput
          config={resetPasswordFormControls.confirmPassword}
          type="password"
        />

        <AppButton
          className="submitBtn"
          text="OK"
          htmlType="submit"
          disabled={isLoading}
        />
      </Form>
    </AuthLayout>
  );
};
