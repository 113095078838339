import { IPortfolioImport } from "../modules/dashboard/interfaces/portfolio";
import { ApiClient } from "./apiClient";

export const portfolioApi = {
  getPortfolioImportStatus: (id: string) =>
    ApiClient.get({ path: "portfolio/portfolio-import-status/" + id }),

  getPortfolioImportCSV: () =>
    ApiClient.get({ path: "portfolio/portfolio-import-csv-get" }),

  importPortfolioStart: (data: IPortfolioImport) =>
    ApiClient.post({ path: "portfolio/import", body: data }),

  getPortfolioImports: () =>
    ApiClient.get({ path: "portfolio/portfolioImports" }),
};
